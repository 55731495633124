import { Link } from "react-router-dom";
import { FaFacebook, FaLinkedin, FaInstagram } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";

const Footer = () => {
  return (
    <div className="w-full bg-[#E63946]">
      <div className="container mx-auto py-10 text-white">
        <div className="grid grid-cols-10 gap-4 max-md:px-2">
          {/* About us */}
          <div className="col-span-4 max-md:col-span-6 max-sm:col-span-10 flex items-start px-2 justify-center max-md:justify-start">
            <div>
              <h4 className="font-bold text-2xl">About Us</h4>
              <div className="w-[90px] h-[2px] bg-white"></div>
              <p className="mt-3">
                Welcome to <b>Matrimony</b>, your portal to lasting love. With
                advanced matchmaking, robust security, and a commitment to
                diversity, we ensure a safe and delightful journey.
              </p>
              <p>
                Your privacy is our priority. Join us for a seamless path to
                your perfect match.
              </p>
              <p>
                Because at Match 2 Marriage, we believe in making love stories
                come true.
              </p>
              <div className="flex items-center mt-3 text-xl gap-3">
                <a
                  href="https://www.facebook.com"
                  target="_blank"
                  rel="noreferrer"
                >
                  <FaFacebook />
                </a>
                <a
                  href="https://www.facebook.com"
                  target="_blank"
                  rel="noreferrer"
                >
                  <FaXTwitter />
                </a>
                <a
                  href="https://www.facebook.com"
                  target="_blank"
                  rel="noreferrer"
                >
                  <FaLinkedin />
                </a>
                <a
                  href="https://www.facebook.com"
                  target="_blank"
                  rel="noreferrer"
                >
                  <FaInstagram />
                </a>
              </div>
            </div>
          </div>

          {/* Help and Support */}
          <div className="col-span-2 max-md:col-span-4 max-sm:col-span-10 flex items-start justify-center max-md:justify-start">
            <div>
              <h4 className="font-bold text-2xl">Help</h4>
              <div className="w-[50px] h-[2px] bg-white"></div>
              <div className="mt-3 text-lg hover:underline">
                <Link to="/contact">Contact Us</Link>
              </div>
              <div className="mt-[0.35rem] text-lg hover:underline">
                <Link to="/successstories">Success Story</Link>
              </div>
              <div className="mt-[0.35rem] text-lg hover:underline">
                <Link to="/membershipdemo">Membership Demograph</Link>
              </div>
            </div>
          </div>

          {/* Information */}
          <div className="col-span-2 max-md:col-span-6 max-sm:col-span-10 flex items-start justify-center max-md:justify-start">
            <div>
              <h4 className="font-bold text-2xl">Information</h4>
              <div className="w-[110px] h-[2px] bg-white"></div>
              <div className="mt-3 text-lg hover:underline">
                <Link to="/privacy-policy">Privacy Policy</Link>
              </div>
              <div className="mt-[0.3rem] text-lg hover:underline">
                <Link to="/refund-policy">Refund Policy</Link>
              </div>
              <div className="mt-[0.3rem] text-lg hover:underline">
                <Link to="/terms">Terms and Conditions</Link>
              </div>
            </div>
          </div>

          {/* Others */}
          <div className="col-span-2 max-md:col-span-4 max-sm:col-span-10 flex items-start justify-center max-md:justify-start">
            <div>
              <h4 className="font-bold text-2xl">Others</h4>
              <div className="w-[60px] h-[2px] bg-white"></div>
              <div className="mt-3 text-lg hover:underline">
                <Link to="/register">Register</Link>
              </div>
              <div className="mt-[0.35rem] text-lg hover:underline">
                <Link to="/login">Login</Link>
              </div>
            </div>
          </div>
        </div>
        <p className="flex mt-4 items-center justify-center">
          &copy; 2023 Yash All rights reserved
        </p>
      </div>
    </div>
  );
};

export default Footer;
