import { useState } from "react";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";
import registerImage from "../assets/register.png";
import axios from "axios";

const RegisterForm = () => {
  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [gender, setGender] = useState("");
  const [password, setPassword] = useState("");
  const [dob, setdob] = useState("")
  const [hidden, setHidden] = useState(true);

  const navigate = useNavigate();
  const handleSubmit = async (e) => {
    e.preventDefault();
    await registerForm();
    // navigate("/onboarding");
  };

  const registerForm = async () => {
    try {
      const data = {
        uniqueid: "",
        first_name: firstname,
        last_name: lastname,
        gender: gender,
        email: email,
        password: password,
        dob: dob,
        contact_number: phone
      };

      const res = await axios.post('https://sriyamatrimony.com/apis/basicreg.php', data, {
        headers: {
          'Content-Type': 'application/json',
        },
      });

      // Check if response is OK (status 200-299)
      console.log(JSON.stringify(res.data));
      alert("Form submitted successfully");

    } catch (error) {
      console.error('Network error:', error.response ? error.response.data : error.message);
      alert("A network error occurred while submitting the form.");
    }
  };

  return (
    <>
      <div className="max-w-[1500px] w-full mx-auto grid grid-cols-2 py-[120px]">
        {/* Left side with image */}
        <div className="hidden lg:flex h-full items-center justify-center col-span-2 lg:col-span-1">
          <img
            src={registerImage}
            alt="Register Illustration"
            className="max-h-[400px] object-cover rounded-l-lg"
          />
        </div>

        {/* Right side with form */}
        <div className="w-full col-span-2 lg:col-span-1 flex items-center justify-center">
          <form
            onSubmit={handleSubmit}
            className="p-4 w-[95%] max-w-[500px] bg-[#fffff0]  text-[#5B3A29] opacity-95 rounded shadow-md"
          >
            <h1 className="py-5 text-center text-black text-2xl font-semibold">
              Register For Free
            </h1>

            {/* Firstname and Lastname */}
            <div className="w-full flex flex-col md:flex-row md:gap-4">
              <div className="flex flex-col w-full md:w-1/2 mb-3">
                <label htmlFor="firstname" className="text-md font-medium">
                  First Name *
                </label>
                <input
                  type="text"
                  id="firstname"
                  value={firstname}
                  placeholder="Enter your first name"
                  className="w-full border border-[#D8CDAF] rounded px-2 py-1  text-lg"
                  onChange={(e) => setFirstname(e.target.value)}
                />
              </div>
              <div className="flex flex-col w-full md:w-1/2 mb-3">
                <label htmlFor="lastname" className="text-md font-medium">
                  Last Name *
                </label>
                <input
                  type="text"
                  id="lastname"
                  value={lastname}
                  placeholder="Enter your last name"
                  className="w-full border border-[#D8CDAF] rounded px-2 py-1  text-lg"
                  onChange={(e) => setLastname(e.target.value)}
                />
              </div>
            </div>

            {/* Phone Number and Gender */}
            <div className="w-full flex flex-col md:flex-row md:gap-4">
              <div className="flex flex-col w-full md:w-1/2 mb-3">
                <label htmlFor="phone" className="text-md font-medium">
                  Phone Number *
                </label>
                <input
                  type="tel"
                  id="phone"
                  value={phone}
                  placeholder="Enter your phone number"
                  className="w-full border border-[#D8CDAF] rounded px-2 py-1  text-lg"
                  onChange={(e) => setPhone(e.target.value)}
                />
              </div>
              <div className="flex flex-col w-full md:w-1/2 mb-3">
                <label htmlFor="gender" className="text-md font-medium">
                  Gender *
                </label>
                <select
                  id="gender"
                  value={gender}
                  onChange={(e) => setGender(e.target.value)}
                  className="w-full border border-[#D8CDAF] rounded px-2 py-1  text-lg"
                >
                  <option value="">Select gender</option>
                  <option value="male">Male</option>
                  <option value="female">Female</option>
                  <option value="other">Other</option>
                </select>
              </div>
            </div>
            {/* Date of Birth */}
            <div>
              <label htmlFor="dateOfBirth" className="block font-medium">
                Date of Birth *
              </label>
              <input
                type="date"
                id="dateOfBirth"
                name="dateOfBirth"
                value={dob}
                onChange={(e) => setdob(e.target.value)}
                className="mt-1 w-full border border-[#D8CDAF] rounded px-3 py-2"
                max={new Date().toISOString().split("T")[0]} // Ensure no future dates
              />
            </div>
            {/* Email */}
            <div className="w-full mb-3">
              <label htmlFor="email" className="text-md font-medium">
                Email *
              </label>
              <input
                type="email"
                id="email"
                value={email}
                placeholder="Enter your email address"
                className="w-full border border-[#D8CDAF] rounded px-2 py-1  text-lg"
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>

            {/* Password */}
            <div className="w-full relative flex flex-col mb-3">
              <label htmlFor="password" className="text-md font-medium">
                Password *
              </label>
              <input
                type={hidden ? "password" : "text"}
                id="password"
                value={password}
                placeholder="Enter your password"
                onChange={(e) => setPassword(e.target.value)}
                className="w-full border border-[#D8CDAF] rounded px-2 py-1  text-lg"
              />
              <div
                onClick={() => setHidden((prev) => !prev)}
                className="absolute right-0 top-0 mt-9 mr-3 cursor-pointer"
              >
                {hidden ? <FaEyeSlash /> : <FaEye />}
              </div>
            </div>

            <p className="pt-3 text-sm">
              Already have an account?{" "}
              <Link
                to="/login"
                className="text-blue-700 hover:underline cursor-pointer"
              >
                Login Here
              </Link>
            </p>

            <button
              type="submit"
              className="my-3 py-2 text-white font-semibold rounded text-center w-full bg-[#a50029] hover:bg-[#800020]"
            >
              Register
            </button>
          </form>
        </div>
      </div>
    </>
  );
};

export default RegisterForm;
