import Footer from "../components/Footer";
import Header from "../components/Header";
import heroBg from "../assets/herobg.png";
import RegisterForm from "../components/RegisterForm";

const Register = () => {
  return (
    <>
      <Header />
      <div
        className="w-full min-h-[95vh] flex items-center justify-center bg-gray-200"
        style={{
          backgroundImage: `url(${heroBg})`,
          backgroundSize: "cover",
          backgroundBlendMode: "multiply",
          backgroundPosition: "left",
        }}
      >
        <RegisterForm />
      </div>
      <Footer />
    </>
  );
};

export default Register;
