// src/components/OnBoarding/Step3.js
import React from "react";

const Step3 = ({ formData, handleInputChange }) => {
  return (
    <div>
      <h2 className="pt-3 pb-5 font-semibold text-2xl">Family Details</h2>

      {/* Family Status */}
      <div className="flex flex-col md:flex-row md:space-x-4 mb-4">
        <div className="flex-1">
          <label htmlFor="familyStatus" className="block font-medium">
            Family Status
          </label>
          <select
            id="familyStatus"
            name="familyStatus"
            value={formData.familyStatus}
            onChange={handleInputChange}
            className="mt-1 w-full border border-gray-300 rounded px-3 py-2"
          >
            <option value="">Select</option>
            <option value="Rich">Rich</option>
            <option value="Middle Class">Middle Class</option>
            <option value="Average">Average</option>
          </select>
        </div>

        <div className="flex-1">
          <label htmlFor="familyType" className="block font-medium">
            Family Type
          </label>
          <select
            id="familyType"
            name="familyType"
            value={formData.familyType}
            onChange={handleInputChange}
            className="mt-1 w-full border border-gray-300 rounded px-3 py-2"
          >
            <option value="">Select</option>
            <option value="Joint Family">Joint Family</option>
            <option value="Nuclear Family">Nuclear Family</option>
          </select>
        </div>
      </div>

      {/* Father's Details */}
      <h3 className="font-medium mb-2 text-black text-lg">Father's Details</h3>
      <div className="flex flex-col md:flex-row md:space-x-4 mb-4">
        <div className="flex-1">
          <label htmlFor="fatherName" className="block font-medium">
            Father's Name *
          </label>
          <input
            type="text"
            id="fatherName"
            name="fatherName"
            value={formData.fatherName}
            onChange={handleInputChange}
            className="mt-1 w-full border border-gray-300 rounded px-3 py-2"
            placeholder="Enter Father's Name"
          />
        </div>

        <div className="flex-1">
          <label htmlFor="fatherReligion" className="block font-medium">
            Father's Religion
          </label>
          <input
            type="text"
            id="fatherReligion"
            name="fatherReligion"
            value={formData.fatherReligion}
            onChange={handleInputChange}
            className="mt-1 w-full border border-gray-300 rounded px-3 py-2"
            placeholder="Enter Father's Religion"
          />
        </div>
      </div>

      <div className="flex flex-col md:flex-row md:space-x-4 mb-4">
        <div className="flex-1">
          <label htmlFor="fatherCaste" className="block font-medium">
            Father's Caste
          </label>
          <input
            type="text"
            id="fatherCaste"
            name="fatherCaste"
            value={formData.fatherCaste}
            onChange={handleInputChange}
            className="mt-1 w-full border border-gray-300 rounded px-3 py-2"
            placeholder="Enter Father's Caste"
          />
        </div>

        <div className="flex-1">
          <label className="block font-medium">Is Converted Caste</label>
          <select
            name="isConvertedCasteFather"
            id="isConvertedCasteFather"
            value={formData.isConvertedCasteFather}
            onChange={handleInputChange}
            className="mt-1 w-full border border-[#D8CDAF] rounded px-3 py-2"
          >
            <option value="">Select Option</option>
            <option value="yes">Yes</option>
            <option value="no">No</option>
          </select>
        </div>
      </div>

      <div className="flex flex-col md:flex-row md:space-x-4 mb-4">
        <div className="flex-1">
          <label htmlFor="fatherStatus" className="block font-medium">
            Father's Status
          </label>
          <select
            id="fatherStatus"
            name="fatherStatus"
            value={formData.fatherStatus}
            onChange={handleInputChange}
            className="mt-1 w-full border border-gray-300 rounded px-3 py-2"
          >
            <option value="">Select</option>
            <option value="Alive">Alive</option>
            <option value="Late">Late</option>
          </select>
        </div>

        {formData.fatherStatus === "Alive" && (
          <div className="flex-1">
            <h4 className="font-medium mb-2">If Alive</h4>
            <div className="flex flex-col md:flex-row md:space-x-4 mb-4">
              <div className="flex-1">
                <label
                  htmlFor="fatherHealthCondition"
                  className="block font-medium"
                >
                  Health Condition
                </label>
                <input
                  type="text"
                  id="fatherHealthCondition"
                  name="fatherHealthCondition"
                  value={formData.fatherHealthCondition}
                  onChange={handleInputChange}
                  className="mt-1 w-full border border-gray-300 rounded px-3 py-2"
                  placeholder="Enter Health Condition"
                />
              </div>

              <div className="flex-1">
                <label
                  htmlFor="fatherMobileNumber"
                  className="block font-medium"
                >
                  Mobile Number
                </label>
                <input
                  type="text"
                  id="fatherMobileNumber"
                  name="fatherMobileNumber"
                  value={formData.fatherMobileNumber}
                  onChange={handleInputChange}
                  className="mt-1 w-full border border-gray-300 rounded px-3 py-2"
                  placeholder="Enter Mobile Number"
                />
              </div>
            </div>

            <div className="flex flex-col md:flex-row md:space-x-4 mb-4">
              <div className="flex-1">
                <label htmlFor="fatherEmployed" className="block font-medium">
                  Employed/Unemployed/Agriculture
                </label>
                <select
                  id="fatherEmployed"
                  name="fatherEmployed"
                  value={formData.fatherEmployed}
                  onChange={handleInputChange}
                  className="mt-1 w-full border border-gray-300 rounded px-3 py-2"
                >
                  <option value="">Select</option>
                  <option value="Employed">Employed</option>
                  <option value="Unemployed">Unemployed</option>
                  <option value="Agriculture">Agriculture</option>
                </select>
              </div>

              {formData.fatherEmployed === "Employed" && (
                <div className="flex-1">
                  <label
                    htmlFor="fatherProfession"
                    className="block font-medium"
                  >
                    Profession
                  </label>
                  <input
                    type="text"
                    id="fatherProfession"
                    name="fatherProfession"
                    value={formData.fatherProfession}
                    onChange={handleInputChange}
                    className="mt-1 w-full border border-gray-300 rounded px-3 py-2"
                    placeholder="Enter Profession"
                  />
                </div>
              )}
            </div>

            {formData.fatherEmployed === "Employed" && (
              <div className="flex flex-col md:flex-row md:space-x-4 mb-4">
                <div className="flex-1">
                  <label
                    htmlFor="fatherAnnualIncome"
                    className="block font-medium"
                  >
                    Annual Income
                  </label>
                  <input
                    type="text"
                    id="fatherAnnualIncome"
                    name="fatherAnnualIncome"
                    value={formData.fatherAnnualIncome}
                    onChange={handleInputChange}
                    className="mt-1 w-full border border-gray-300 rounded px-3 py-2"
                    placeholder="Enter Annual Income"
                  />
                </div>

                <div className="flex-1">
                  <label
                    htmlFor="fatherDesignation"
                    className="block font-medium"
                  >
                    Designation
                  </label>
                  <input
                    type="text"
                    id="fatherDesignation"
                    name="fatherDesignation"
                    value={formData.fatherDesignation}
                    onChange={handleInputChange}
                    className="mt-1 w-full border border-gray-300 rounded px-3 py-2"
                    placeholder="Enter Designation"
                  />
                </div>
              </div>
            )}

            {formData.fatherEmployed === "Employed" && (
              <div className="flex flex-col md:flex-row md:space-x-4 mb-4">
                <div className="flex-1">
                  <label htmlFor="fatherAddress" className="block font-medium">
                    Address
                  </label>
                  <input
                    type="text"
                    id="fatherAddress"
                    name="fatherAddress"
                    value={formData.fatherAddress}
                    onChange={handleInputChange}
                    className="mt-
                    mt-1 w-full border border-gray-300 rounded px-3 py-2"
                    placeholder="Enter Address"
                  />
                </div>

                <div className="flex-1">
                  <label
                    htmlFor="fatherPropertyDetails"
                    className="block font-medium"
                  >
                    Property Details
                  </label>
                  <input
                    type="text"
                    id="fatherPropertyDetails"
                    name="fatherPropertyDetails"
                    value={formData.fatherPropertyDetails}
                    onChange={handleInputChange}
                    className="mt-1 w-full border border-gray-300 rounded px-3 py-2"
                    placeholder="Enter Property Details"
                  />
                </div>
              </div>
            )}

            {formData.fatherEmployed === "Employed" && (
              <div className="flex-1 mb-4">
                <label htmlFor="fatherPension" className="block font-medium">
                  Pension
                </label>
                <input
                  type="text"
                  id="fatherPension"
                  name="fatherPension"
                  value={formData.fatherPension}
                  onChange={handleInputChange}
                  className="mt-1 w-full border border-gray-300 rounded px-3 py-2"
                  placeholder="Enter Pension Amount"
                />
              </div>
            )}
          </div>
        )}
      </div>

      {/* Mother's Details */}
      <h3 className="font-medium mb-2 text-black text-lg">Mother's Details</h3>
      <div className="flex flex-col md:flex-row md:space-x-4 mb-4">
        <div className="flex-1">
          <label htmlFor="motherName" className="block font-medium">
            Mother's Name *
          </label>
          <input
            type="text"
            id="motherName"
            name="motherName"
            value={formData.motherName}
            onChange={handleInputChange}
            className="mt-1 w-full border border-gray-300 rounded px-3 py-2"
            placeholder="Enter Mother's Name"
          />
        </div>

        <div className="flex-1">
          <label htmlFor="motherMaidenName" className="block font-medium">
            Mother's Maiden Name
          </label>
          <input
            type="text"
            id="motherMaidenName"
            name="motherMaidenName"
            value={formData.motherMaidenName}
            onChange={handleInputChange}
            className="mt-1 w-full border border-gray-300 rounded px-3 py-2"
            placeholder="Enter Mother's Maiden Name"
          />
        </div>
      </div>

      <div className="flex flex-col md:flex-row md:space-x-4 mb-4">
        <div className="flex-1">
          <label htmlFor="motherReligion" className="block font-medium">
            Mother's Religion
          </label>
          <input
            type="text"
            id="motherReligion"
            name="motherReligion"
            value={formData.motherReligion}
            onChange={handleInputChange}
            className="mt-1 w-full border border-gray-300 rounded px-3 py-2"
            placeholder="Enter Mother's Religion"
          />
        </div>

        <div className="flex-1">
          <label htmlFor="motherCaste" className="block font-medium">
            Mother's Caste
          </label>
          <input
            type="text"
            id="motherCaste"
            name="motherCaste"
            value={formData.motherCaste}
            onChange={handleInputChange}
            className="mt-1 w-full border border-gray-300 rounded px-3 py-2"
            placeholder="Enter Mother's Caste"
          />
        </div>
      </div>

      <div className="flex flex-col md:flex-row md:space-x-4 mb-4">
        <div className="flex-1">
          <label className="block font-medium">Is Converted Caste</label>
          <select
            name="isConvertedCasteMother"
            id="isConvertedCasteMother"
            value={formData.isConvertedCasteMother}
            onChange={handleInputChange}
            className="mt-1 w-full border border-[#D8CDAF] rounded px-3 py-2"
          >
            <option value="">Select Option</option>
            <option value="yes">Yes</option>
            <option value="no">No</option>
          </select>
        </div>

        <div className="flex-1">
          <label htmlFor="motherStatus" className="block font-medium">
            Mother's Status
          </label>
          <select
            id="motherStatus"
            name="motherStatus"
            value={formData.motherStatus}
            onChange={handleInputChange}
            className="mt-1 w-full border border-gray-300 rounded px-3 py-2"
          >
            <option value="">Select</option>
            <option value="Alive">Alive</option>
            <option value="Late">Late</option>
          </select>
        </div>
      </div>

      {formData.motherStatus === "Alive" && (
        <div className="flex flex-col md:flex-row md:space-x-4 mb-4">
          <h4 className="font-medium mb-2">If Alive</h4>
          <div className="flex flex-col md:flex-row md:space-x-4 mb-4">
            <div className="flex-1">
              <label
                htmlFor="motherHealthCondition"
                className="block font-medium"
              >
                Health Condition
              </label>
              <input
                type="text"
                id="motherHealthCondition"
                name="motherHealthCondition"
                value={formData.motherHealthCondition}
                onChange={handleInputChange}
                className="mt-1 w-full border border-gray-300 rounded px-3 py-2"
                placeholder="Enter Health Condition"
              />
            </div>

            <div className="flex-1">
              <label htmlFor="motherMobileNumber" className="block font-medium">
                Mobile Number
              </label>
              <input
                type="text"
                id="motherMobileNumber"
                name="motherMobileNumber"
                value={formData.motherMobileNumber}
                onChange={handleInputChange}
                className="mt-1 w-full border border-gray-300 rounded px-3 py-2"
                placeholder="Enter Mobile Number"
              />
            </div>
          </div>

          <div className="flex flex-col md:flex-row md:space-x-4 mb-4">
            <div className="flex-1">
              <label htmlFor="motherEmployed" className="block font-medium">
                Employed/House Wife
              </label>
              <select
                id="motherEmployed"
                name="motherEmployed"
                value={formData.motherEmployed}
                onChange={handleInputChange}
                className="mt-1 w-full border border-gray-300 rounded px-3 py-2"
              >
                <option value="">Select</option>
                <option value="Employed">Employed</option>
                <option value="House Wife">House Wife</option>
              </select>
            </div>

            {formData.motherEmployed === "Employed" && (
              <div className="flex-1">
                <label htmlFor="motherProfession" className="block font-medium">
                  Profession
                </label>
                <input
                  type="text"
                  id="motherProfession"
                  name="motherProfession"
                  value={formData.motherProfession}
                  onChange={handleInputChange}
                  className="mt-1 w-full border border-gray-300 rounded px-3 py-2"
                  placeholder="Enter Profession"
                />
              </div>
            )}
          </div>

          {formData.motherEmployed === "Employed" && (
            <div className="flex flex-col md:flex-row md:space-x-4 mb-4">
              <div className="flex-1">
                <label
                  htmlFor="motherAnnualIncome"
                  className="block font-medium"
                >
                  Annual Income
                </label>
                <input
                  type="text"
                  id="motherAnnualIncome"
                  name="motherAnnualIncome"
                  value={formData.motherAnnualIncome}
                  onChange={handleInputChange}
                  className="mt-1 w-full border border-gray-300 rounded px-3 py-2"
                  placeholder="Enter Annual Income"
                />
              </div>

              <div className="flex-1">
                <label
                  htmlFor="motherDesignation"
                  className="block font-medium"
                >
                  Designation
                </label>
                <input
                  type="text"
                  id="motherDesignation"
                  name="motherDesignation"
                  value={formData.motherDesignation}
                  onChange={handleInputChange}
                  className="mt-1 w-full border border-gray-300 rounded px-3 py-2"
                  placeholder="Enter Designation"
                />
              </div>
            </div>
          )}

          {formData.motherEmployed === "Employed" && (
            <div className="flex flex-col md:flex-row md:space-x-4 mb-4">
              <div className="flex-1">
                <label htmlFor="motherAddress" className="block font-medium">
                  Address
                </label>
                <input
                  type="text"
                  id="motherAddress"
                  name="motherAddress"
                  value={formData.motherAddress}
                  onChange={handleInputChange}
                  className="mt-1 w-full border border-gray-300 rounded px-3 py-2"
                  placeholder="Enter Address"
                />
              </div>

              <div className="flex-1">
                <label
                  htmlFor="motherPropertyDetails"
                  className="block font-medium"
                >
                  Property Details
                </label>
                <input
                  type="text"
                  id="motherPropertyDetails"
                  name="motherPropertyDetails"
                  value={formData.motherPropertyDetails}
                  onChange={handleInputChange}
                  className="mt-1 w-full border border-gray-300 rounded px-3 py-2"
                  placeholder="Enter Property Details"
                />
              </div>
            </div>
          )}

          {formData.motherEmployed === "Employed" && (
            <div className="flex-1 mb-4">
              <label htmlFor="motherPension" className="block font-medium">
                Pension
              </label>
              <input
                type="text"
                id="motherPension"
                name="motherPension"
                value={formData.motherPension}
                onChange={handleInputChange}
                className="mt-1 w-full border border-gray-300 rounded px-3 py-2"
                placeholder="Enter Pension Amount"
              />
            </div>
          )}
        </div>
      )}

      {/* Family's Permanent and Present Address */}
      <div className="flex flex-col md:flex-row md:space-x-4 mb-4">
        <div className="flex-1">
          <label htmlFor="permanentAddress" className="block font-medium">
            Family's Permanent Address
          </label>
          <input
            type="text"
            id="permanentAddress"
            name="permanentAddress"
            value={formData.permanentAddress}
            onChange={handleInputChange}
            className="mt-1 w-full border border-gray-300 rounded px-3 py-2"
            placeholder="Enter Permanent Address"
          />
        </div>

        <div className="flex-1">
          <label htmlFor="presentAddress" className="block font-medium">
            Family's Present Address
          </label>
          <input
            type="text"
            id="presentAddress"
            name="presentAddress"
            value={formData.presentAddress}
            onChange={handleInputChange}
            className="mt-1 w-full border border-gray-300 rounded px-3 py-2"
            placeholder="Enter Present Address"
          />
        </div>
      </div>

      {/* Siblings Details */}
      <div className="mb-4">
        <h3 className="font-medium mb-2 text-black text-lg">Siblings</h3>
        <div className="flex flex-col md:flex-row md:space-x-4 mb-4">
          <div className="flex-1">
            <label htmlFor="numberOfBrothers" className="block font-medium">
              Number of Brothers *
            </label>
            <input
              type="number"
              id="numberOfBrothers"
              name="numberOfBrothers"
              value={formData.numberOfBrothers}
              onChange={handleInputChange}
              className="mt-1 w-full border border-gray-300 rounded px-3 py-2"
              placeholder="Enter Number of Brothers"
            />
          </div>

          <div className="flex-1">
            <label htmlFor="numberOfSisters" className="block font-medium">
              Number of Sisters *
            </label>
            <input
              type="number"
              id="numberOfSisters"
              name="numberOfSisters"
              value={formData.numberOfSisters}
              onChange={handleInputChange}
              className="mt-1 w-full border border-gray-300 rounded px-3 py-2"
              placeholder="Enter Number of Sisters"
            />
          </div>
        </div>

        {/* Brother's Details */}
        {Array.from({ length: formData.numberOfBrothers }).map((_, index) => (
          <div key={index} className="mb-4">
            <h4 className="font-medium mb-2">Brother {index + 1} Details</h4>
            <div className="flex flex-col md:flex-row md:space-x-4 mb-4">
              <div className="flex-1">
                <label
                  htmlFor={`brotherName${index}`}
                  className="block font-medium"
                >
                  Name of Brother
                </label>
                <input
                  type="text"
                  id={`brotherName${index}`}
                  name={`brotherName${index}`}
                  onChange={handleInputChange}
                  className="mt-1 w-full border border-gray-300 rounded px-3 py-2"
                  placeholder="Enter Brother's Name"
                />
              </div>

              <div className="flex-1">
                <label
                  htmlFor={`brotherAge${index}`}
                  className="block font-medium"
                >
                  Age
                </label>
                <input
                  type="number"
                  id={`brotherAge${index}`}
                  name={`brotherAge${index}`}
                  onChange={handleInputChange}
                  className="mt-1 w-full border border-gray-300 rounded px-3 py-2"
                  placeholder="Enter Brother's Age"
                />
              </div>
            </div>
            <div className="flex flex-col md:flex-row md:space-x-4 mb-4">
              <div className="flex-1">
                <label
                  htmlFor={`brotherMaritalStatus${index}`}
                  className="block font-medium mb-2"
                >
                  Marital Status
                </label>
                <select
                  id={`brotherMaritalStatus${index}`}
                  name={`brotherMaritalStatus${index}`}
                  onChange={handleInputChange}
                  className="mt-1 w-full border border-gray-300 rounded px-3 py-2"
                >
                  <option value="">Select</option>
                  <option value="Married">Married</option>
                  <option value="Single">Single</option>
                  <option value="Divorced">Divorced</option>
                </select>
              </div>

              <div className="flex-1">
                <label
                  htmlFor={`brotherElderOrYounger${index}`}
                  className="block font-medium mb-2"
                >
                  Elder or Younger
                </label>
                <select
                  id={`brotherElderOrYounger${index}`}
                  name={`brotherElderOrYounger${index}`}
                  onChange={handleInputChange}
                  className="mt-1 w-full border border-gray-300 rounded px-3 py-2"
                >
                  <option value="">Select</option>
                  <option value="Elder">Elder</option>
                  <option value="Younger">Younger</option>
                </select>
              </div>
            </div>
          </div>
        ))}

        {/* Sister's Details */}
        {Array.from({ length: formData.numberOfSisters }).map((_, index) => (
          <div key={index} className="mb-4">
            <h4 className="font-medium mb-2">Sister {index + 1} Details</h4>
            <div className="flex flex-col md:flex-row md:space-x-4 mb-4">
              <div className="flex-1">
                <label
                  htmlFor={`sisterName${index}`}
                  className="block font-medium"
                >
                  Name of Sister
                </label>
                <input
                  type="text"
                  id={`sisterName${index}`}
                  name={`sisterName${index}`}
                  onChange={handleInputChange}
                  className="mt-1 w-full border border-gray-300 rounded px-3 py-2"
                  placeholder="Enter Sister's Name"
                />
              </div>

              <div className="flex-1">
                <label
                  htmlFor={`sisterAge${index}`}
                  className="block font-medium"
                >
                  Age
                </label>
                <input
                  type="number"
                  id={`sisterAge${index}`}
                  name={`sisterAge${index}`}
                  onChange={handleInputChange}
                  className="mt-1 w-full border border-gray-300 rounded px-3 py-2"
                  placeholder="Enter Sister's Age"
                />
              </div>
            </div>
            <div className="flex flex-col md:flex-row md:space-x-4 mb-4">
              <div className="flex-1">
                <label
                  htmlFor={`sisterMaritalStatus${index}`}
                  className="block font-medium"
                >
                  Marital Status
                </label>
                <select
                  id={`sisterMaritalStatus${index}`}
                  name={`sisterMaritalStatus${index}`}
                  onChange={handleInputChange}
                  className="mt-1 w-full border border-gray-300 rounded px-3 py-2"
                >
                  <option value="">Select</option>
                  <option value="Married">Married</option>
                  <option value="Single">Single</option>
                  <option value="Divorced">Divorced</option>
                </select>
              </div>

              <div className="flex-1">
                <label
                  htmlFor={`sisterElderOrYounger${index}`}
                  className="block font-medium"
                >
                  Elder or Younger
                </label>
                <select
                  id={`sisterElderOrYounger${index}`}
                  name={`sisterElderOrYounger${index}`}
                  onChange={handleInputChange}
                  className="mt-1 w-full border border-gray-300 rounded px-3 py-2"
                >
                  <option value="">Select</option>
                  <option value="Elder">Elder</option>
                  <option value="Younger">Younger</option>
                </select>
              </div>
            </div>
          </div>
        ))}
      </div>

      {/* Reference Details */}
      <div className="mb-4">
        <h3 className="font-medium mb-2 text-black text-lg">
          Reference Details
        </h3>
        <div className="flex flex-col md:flex-row md:space-x-4 mb-4">
          <div className="flex-1">
            <label htmlFor="referenceName" className="block font-medium">
              Reference Name
            </label>
            <input
              type="text"
              id="referenceName"
              name="referenceName"
              value={formData.referenceName}
              onChange={handleInputChange}
              className="mt-1 w-full border border-gray-300 rounded px-3 py-2"
              placeholder="Enter Reference Name"
            />
          </div>

          <div className="flex-1">
            <label
              htmlFor="referenceMobileNumber"
              className="block font-medium"
            >
              Reference Mobile Number
            </label>
            <input
              type="text"
              id="referenceMobileNumber"
              name="referenceMobileNumber"
              value={formData.referenceMobileNumber}
              onChange={handleInputChange}
              className="mt-1 w-full border border-gray-300 rounded px-3 py-2"
              placeholder="Enter Reference Mobile Number"
            />
          </div>
        </div>

        <div className="flex flex-col md:flex-row md:space-x-4 mb-4">
          <div className="flex-1">
            <label htmlFor="relation" className="block font-medium">
              Relation
            </label>
            <input
              type="text"
              id="relation"
              name="relation"
              value={formData.relation}
              onChange={handleInputChange}
              className="mt-1 w-full border border-gray-300 rounded px-3 py-2"
              placeholder="Enter Relation"
            />
          </div>

          <div className="flex-1">
            <label htmlFor="referenceAddress" className="block font-medium">
              Reference Address
            </label>
            <input
              type="text"
              id="referenceAddress"
              name="referenceAddress"
              value={formData.referenceAddress}
              onChange={handleInputChange}
              className="mt-1 w-full border border-gray-300 rounded px-3 py-2"
              placeholder="Enter Reference Address"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Step3;
