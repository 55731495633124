import heroImg from "../../assets/hero.png";
import heroBg from "../../assets/herobg.png";

const Hero = () => {
  return (
    <div
      className="relative min-h-[95vh] w-full flex items-center justify-center"
      style={{
        backgroundImage: `url(${heroBg})`,
        backgroundSize: "cover",
        backgroundPosition: "left",
      }}
    >
      <div className="max-w-[1500px] mx-auto">
        {/* <div className="absolute inset-0 bg-black opacity-15"></div>{" "} */}
        {/* Optional overlay for contrast */}
        <div className="relative z-[7] grid grid-cols-2 max-md:grid-cols-1 w-full gap-2 place-content-center place-items-center">
          {/* Text Section */}
          <div className="col-span-1 flex items-start max-md:items-center justify-center flex-col">
            <div className="text-5xl max-md:text-3xl font-semibold max-w-[390px] max-md:text-center">
              <h1 className="text-4xl max-md:text-2xl">Welcome to </h1>
              <h2 className="text-[#E63946] mb-1">Sriya Matrimony</h2>
            </div>
            <button className="mt-3 px-4 py-2 max-md:py-1 text-white text-lg bg-[#E63946] rounded hover:bg-[#a81861]">
              Know More
            </button>
          </div>
          <div className="col-span-1 max-md:mt-5 flex justify-center items-center max-md:col-md-2">
            <img
              alt="hero"
              src={heroImg}
              className="max-h-[500px] object-cover opacity-90"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Hero;
