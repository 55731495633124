import React from "react";
import Footer from "../components/Footer";
import About from "../components/Home/About";
import Working from "../components/Home/Working";
import Header from "../components/Header";
import Hero from "../components/Home/Hero";
import Register from "../components/Home/Register";

const Home = () => {
  return (
    <>
      <Header />
      <Hero />
      <Register />
      <About />
      <Working />
      <Footer />
    </>
  );
};

export default Home;
