import React, { useState, useEffect } from "react";
import { Link, NavLink, useLocation } from "react-router-dom";
import { VscThreeBars } from "react-icons/vsc";

const Header = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  // const [isScrolled, setIsScrolled] = useState(false);
  const [isScrolled, setIsScrolled] = useState(true);
  const location = useLocation();

  const navItems = [
    { id: 1, name: "Home", link: "/" },
    { id: 2, name: "About", link: "/about" },
    { id: 3, name: "Contact Us", link: "/contact" },
    { id: 4, name: "Help", link: "/help" },
    { id: 5, name: "Success Stories", link: "/successstory" },
  ];

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  // useEffect(() => {
  //   const handleScroll = () => {
  //     if (window.scrollY > 50) {
  //       setIsScrolled(true);
  //     } else {
  //       setIsScrolled(false);
  //     }
  //   };

  //   window.addEventListener("scroll", handleScroll);

  //   return () => {
  //     window.removeEventListener("scroll", handleScroll);
  //   };
  // }, []);

  return (
    <>
      <header
        className={`w-full fixed z-[10] px-4 max-md:px-2 py-4 transition-colors duration-300 ${
          isScrolled ? "bg-white shadow-md" : "bg-[#00000055]"
        }`}
      >
        <div className="flex items-center justify-between">
          {/* Logo Section */}
          <Link to="/" className="flex items-center gap-2">
            <img alt="logo" src="/logo.png" style={{ height: "50px" }} />
            <h2
              className={`text-xl max-lg:hidden font-bold transition-colors duration-300 ${
                isScrolled ? "text-[#E63946]" : "text-white"
              }`}
            >
              Sriya Matrimony
            </h2>
          </Link>

          {/* Navigation Items */}
          <nav className="flex items-center gap-6 max-md:hidden">
            {navItems.map((item) => (
              <NavLink
                key={item.id}
                to={item.link}
                className={({ isActive }) =>
                  `relative text-lg font-medium transition duration-300 group ${
                    location.pathname === item.link
                      ? "text-[#E63946]"
                      : isScrolled
                      ? "text-gray-700 hover:text-[#E63946]"
                      : "text-white hover:text-[#E63946]"
                  }`
                }
              >
                {item.name}
                <span className="absolute bottom-0 left-0 w-0 h-[2px] bg-[#E63946] transition-all duration-300 group-hover:w-full"></span>
              </NavLink>
            ))}
            <NavLink
              to="/login"
              className={({ isActive }) =>
                `py-1 font-semibold px-6 rounded-full border transition-all ${
                  location.pathname === "/login"
                    ? isScrolled
                      ? "bg-white text-[#E63946] border-[#E63946]"
                      : "bg-transparent text-white border-white"
                    : isScrolled
                    ? "bg-[#E63946] text-white hover:bg-white hover:text-[#E63946] border-[#E63946]"
                    : "bg-transparent text-white border-white hover:bg-white hover:text-[#E63946]"
                }`
              }
            >
              Login
            </NavLink>
          </nav>

          {/* Hamburger Menu for Small Screens */}
          <div
            className="flex px-2 items-center justify-center text-2xl cursor-pointer md:hidden"
            onClick={toggleSidebar}
          >
            <VscThreeBars
              className={isScrolled ? "text-[#E63946]" : "text-white"}
            />
          </div>
        </div>
      </header>

      {/* Sidebar */}
      <div
        className={`fixed top-0 right-0 h-full w-64 z-[51] bg-white shadow-md transform transition-transform duration-300 ${
          isSidebarOpen ? "translate-x-0" : "translate-x-full"
        }`}
      >
        <div className="flex flex-col py-8 px-6">
          {navItems.map((item) => (
            <NavLink
              key={item.id}
              to={item.link}
              onClick={toggleSidebar}
              className={({ isActive }) =>
                `text-lg font-medium mb-4 ${
                  location.pathname === item.link
                    ? "text-[#E63946]"
                    : "text-gray-700"
                } hover:text-[#E63946]`
              }
            >
              {item.name}
            </NavLink>
          ))}
          <NavLink
            to="/login"
            onClick={toggleSidebar}
            className="py-1 mt-4 font-semibold px-6 bg-[#E63946] text-white text-center rounded-full hover:bg-white hover:text-[#E63946] border border-[#E63946]"
          >
            Login
          </NavLink>
        </div>
      </div>

      {/* Overlay */}
      {isSidebarOpen && (
        <div
          className="fixed inset-0 bg-black opacity-50 z-50"
          onClick={toggleSidebar}
        ></div>
      )}
    </>
  );
};

export default Header;
