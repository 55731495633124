import { useState } from "react";
import Footer from "../components/Footer";
import Header from "../components/Header";
import { Link } from "react-router-dom";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import loginImage from "../assets/login.png";
import heroBg from "../assets/herobg.png";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [hidden, setHidden] = useState(true);

  const handleSubmit = (e) => {
    e.preventDefault();
  };

  return (
    <>
      <Header />
      <div
        className="w-full min-h-[95vh] flex items-center justify-center bg-gray-200"
        style={{
          backgroundImage: `url(${heroBg})`,
          backgroundSize: "cover",
          backgroundBlendMode: "multiply",
          backgroundPosition: "left",
        }}
      >
        <div className="max-w-[1500px] w-full mx-auto grid grid-cols-2">
          {/* Left side with image */}
          <div className="hidden lg:flex h-full items-center justify-center col-span-2 lg:col-span-1">
            <img
              src={loginImage}
              alt="Login Illustration"
              className="max-h-[400px] object-cover rounded-l-lg"
            />
          </div>

          {/* Right side with form */}
          <div className="w-full col-span-2 lg:col-span-1 flex items-center justify-center">
            <form
              onSubmit={handleSubmit}
              className="p-4 w-[95%] max-w-[420px] bg-[#FFFFF0] text-[#5B3A29] opacity-95 rounded shadow-md"
            >
              <h1 className="py-5 text-center text-black text-2xl font-semibold">
                Sriya Matrimony Login
              </h1>
              {/* Email Input */}
              <div className="w-full flex items-start flex-col gap-1">
                <label htmlFor="email" className="text-md font-medium">
                  Email *
                </label>
                <input
                  type="email"
                  id="email"
                  value={email}
                  placeholder="Enter your Email address"
                  className="w-full border border-[#D8CDAF] rounded px-2 py-1  text-lg"
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
              {/* Password Input with Eye Toggle */}
              <div className="w-full pt-3 relative flex items-start flex-col gap-1">
                <label htmlFor="password" className="text-md font-medium">
                  Password *
                </label>
                <input
                  type={hidden ? "password" : "text"}
                  id="password"
                  placeholder="Enter Your Password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  className="w-full border border-[#D8CDAF] rounded px-2 py-1  text-lg"
                />
                <div
                  onClick={() => setHidden((prev) => !prev)}
                  className="absolute right-3 bottom-3 cursor-pointer text-lg"
                >
                  {hidden ? <FaEyeSlash /> : <FaEye />}
                </div>
              </div>
              {/* Forgot Password */}
              <p className="text-sm hover:underline py-2 cursor-pointer text-blue-700 w-full flex justify-end">
                Forgot Password?
              </p>
              {/* Submit Button */}
              <button
                type="submit"
                className="py-2 text-white font-semibold rounded text-center w-full bg-[#a50029] hover:bg-[#800020]"
              >
                Log In
              </button>
              {/* Register Link */}
              <p className="py-2 text-sm">
                Don't Have an Account?{" "}
                <Link
                  to="/register"
                  className="hover:underline pt-1 text-blue-700 cursor-pointer"
                >
                  Register Here
                </Link>
              </p>
            </form>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Login;
