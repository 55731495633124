import React, { useEffect, useState } from "react";
import axios from "axios";

const Step1 = ({
  handleChildChange,
  formData,
  handleInputChange,
  errors,
  isHindu,
  setFormData,
  selectedMaritalStatus,
}) => {
  const [countries, setCountries] = useState([]);
  const [castes, setCastes] = useState([]);

  useEffect(() => {
    const fetchCountries = async () => {
      try {
        const response = await axios.get(
          "https://sriyamatrimony.com/apis/countries.php"
        );
        if (response.status === "success") {
          setCountries(response.data);
        }
      } catch (error) {
        console.error("Error fetching countries:", error);
      }
    };

    const fetchCaste = async () => {
      try {
        const response = await axios.get(
          `https://sriyamatrimony.com/apis/castes.php`
        );
        if (response.status === "success") {
          setCastes(response.data);
        }
      } catch (error) {
        console.error("Error fetching countries:", error);
      }
    };

    fetchCountries();
    fetchCaste();
  }, []);

  return (
    <>
      {/* 1. Upload Photos */}
      <div className="mb-4">
        <label htmlFor="photo" className="block font-medium">
          Upload Photo *
        </label>
        <input
          type="file"
          id="photo"
          accept="image/*"
          onChange={handleInputChange}
          className="mt-1 w-full border border-[#D8CDAF] rounded px-3 py-2"
        />
        {errors.photo && (
          <p className="text-red-500 pt-1 text-sm">{errors.photo}</p>
        )}
      </div>
      {/* 2. Full Name & 3. Surname */}
      <div className="mb-4 grid grid-cols-1 md:grid-cols-2 gap-4">
        {/* Full Name */}
        <div>
          <label htmlFor="firstName" className="block font-medium">
            First Name *
          </label>
          <input
            type="text"
            id="firstName"
            name="firstName"
            value={formData.firstName}
            onChange={handleInputChange}
            placeholder="Enter your first name"
            className="mt-1 w-full border border-[#D8CDAF] rounded px-3 py-2"
          />
          {errors.firstName && (
            <p className="text-red-500 pt-1 text-sm">{errors.firstName}</p>
          )}
        </div>
        {/* Surname */}
        <div>
          <label htmlFor="surname" className="block font-medium">
            Surname *
          </label>
          <input
            type="text"
            id="surname"
            name="surname"
            value={formData.surname}
            onChange={handleInputChange}
            placeholder="Enter your surname"
            className="mt-1 w-full border border-[#D8CDAF] rounded px-3 py-2"
          />
          {errors.surname && (
            <p className="text-red-500 pt-1 text-sm">{errors.surname}</p>
          )}
        </div>
      </div>
      {/* 4. Gender */}
      <div className="mb-4">
        <label className="block font-medium">Gender *</label>
        <select
          name="gender"
          value={formData.gender}
          onChange={handleInputChange}
          className="mt-1 w-full border border-[#D8CDAF] rounded px-3 py-2"
        >
          <option value="">Select Gender</option>
          <option value="male">Male</option>
          <option value="female">Female</option>
          <option value="other">Other</option>
        </select>
        {errors.gender && (
          <p className="text-red-500 pt-1 text-sm">{errors.gender}</p>
        )}
      </div>
      {/* 5. Mobile Number */}
      <div className="mb-4 grid grid-cols-1 md:grid-cols-2 gap-4">
        {/* Mobile Number */}
        <div>
          <label htmlFor="mobile" className="block font-medium">
            Mobile Number * (Primary)
          </label>
          <input
            type="tel"
            id="mobile"
            name="mobile"
            value={formData.mobile}
            onChange={handleInputChange}
            placeholder="Enter your mobile number"
            className="mt-1 w-full border border-[#D8CDAF] rounded px-3 py-2"
          />
          {errors.mobile && (
            <p className="text-red-500 pt-1 text-sm">{errors.mobile}</p>
          )}
        </div>
        {/* Alternate Mobile Number */}
        <div>
          <label htmlFor="altMobile" className="block font-medium">
            Alternate Mobile Number
          </label>
          <input
            type="tel"
            id="altMobile"
            name="altMobile"
            value={formData.altMobile}
            onChange={handleInputChange}
            placeholder="Enter alternate mobile number"
            className="mt-1 w-full border border-[#D8CDAF] rounded px-3 py-2"
          />
        </div>
      </div>
      {/* 6. Email */}
      <div className="mb-4">
        <label htmlFor="email" className="block font-medium">
          Email *
        </label>
        <input
          type="email"
          id="email"
          name="email"
          value={formData.email}
          onChange={handleInputChange}
          placeholder="Enter your email address"
          className="mt-1 w-full border border-[#D8CDAF] rounded px-3 py-2"
        />
        {errors.email && (
          <p className="text-red-500 pt-1 text-sm">{errors.email}</p>
        )}
      </div>
      {/* 7. ID Proof & ID Proof Number */}
      <div className="mb-4 grid grid-cols-1 md:grid-cols-2 gap-4">
        {/* ID Proof */}
        <div>
          <label htmlFor="idProof" className="block font-medium">
            ID Proof *
          </label>
          <select
            name="idProof"
            id="idProof"
            value={formData.idProof}
            onChange={handleInputChange}
            className="mt-1 w-full border border-[#D8CDAF] rounded px-3 py-2"
          >
            <option value="">Select ID Proof</option>
            <option value="aadhar">Aadhar Card</option>
            <option value="pan">PAN</option>
          </select>
          {errors.idProof && (
            <p className="text-red-500 pt-1 text-sm">{errors.idProof}</p>
          )}
        </div>
        {/* ID Proof Number */}
        <div>
          <label htmlFor="idProofNumber" className="block font-medium">
            ID Proof Number *
          </label>
          <input
            type="text"
            id="idProofNumber"
            name="idProofNumber"
            value={formData.idProofNumber}
            onChange={handleInputChange}
            placeholder="Enter your ID proof number"
            className="mt-1 w-full border border-[#D8CDAF] rounded px-3 py-2"
          />
          {errors.idProofNumber && (
            <p className="text-red-500 pt-1 text-sm">{errors.idProofNumber}</p>
          )}
        </div>
      </div>
      {/* 8. Date of Birth & Time of Birth */}
      <div className="mb-4 grid grid-cols-1 md:grid-cols-2 gap-4">
        {/* Date of Birth */}
        <div>
          <label htmlFor="dateOfBirth" className="block font-medium">
            Date of Birth *
          </label>
          <input
            type="date"
            id="dateOfBirth"
            name="dateOfBirth"
            value={formData.dateOfBirth}
            onChange={handleInputChange}
            className="mt-1 w-full border border-[#D8CDAF] rounded px-3 py-2"
            max={new Date().toISOString().split("T")[0]} // Ensure no future dates
          />
          {errors.dateOfBirth && (
            <p className="text-red-500 pt-1 text-sm">{errors.dateOfBirth}</p>
          )}
        </div>
        {/* Time of Birth */}
        <div>
          <label htmlFor="timeOfBirth" className="block font-medium">
            Time of Birth *
          </label>
          <input
            type="time"
            id="timeOfBirth"
            name="timeOfBirth"
            value={formData.timeOfBirth}
            onChange={handleInputChange}
            className="mt-1 w-full border border-[#D8CDAF] rounded px-3 py-2"
          />
          {errors.timeOfBirth && (
            <p className="text-red-500 pt-1 text-sm">{errors.timeOfBirth}</p>
          )}
        </div>
      </div>
      {/* 9. Birth Place */}
      <div className="mb-4">
        <label htmlFor="birthPlace" className="block font-medium">
          Birth Place *
        </label>
        <input
          type="text"
          id="birthPlace"
          name="birthPlace"
          value={formData.birthPlace}
          onChange={handleInputChange}
          placeholder="Enter your birth place"
          className="mt-1 w-full border border-[#D8CDAF] rounded px-3 py-2"
        />
        {errors.birthPlace && (
          <p className="text-red-500 pt-1 text-sm">{errors.birthPlace}</p>
        )}
      </div>
      {/* 10. Address: Country, State, District, Mandal, Village */}
      <div className="mb-4">
        <h3 className="font-medium mb-2">Address:</h3>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
          {/* Country */}
          <div>
            <label htmlFor="country" className="block font-medium">
              Country *
            </label>
            <select
              name="country"
              id="country"
              value={formData.country}
              onChange={handleInputChange}
              className="mt-1 w-full border border-[#D8CDAF] rounded px-3 py-2"
            >
              <option value="">Select Country</option>
              <option value="india">India</option>
              <option value="usa">Outside</option>
              {/* Add more countries as needed */}
            </select>
            {errors.country && (
              <p className="text-red-500 pt-1 text-sm">{errors.country}</p>
            )}
          </div>
          {/* State */}
          <div>
            <label htmlFor="state" className="block font-medium">
              State *
            </label>
            <select
              name="state"
              id="state"
              value={formData.state}
              onChange={handleInputChange}
              className="mt-1 w-full border border-[#D8CDAF] rounded px-3 py-2"
            >
              <option value="">Select State</option>
              {/* Dynamically populate states based on country */}
              <option value="telangana">Andhra Pradesh</option>
              <option value="telangana">Telangana</option>
              <option value="karnataka">Karnataka</option>
              {/* Add more states as needed */}
            </select>
            {errors.state && (
              <p className="text-red-500 pt-1 text-sm">{errors.state}</p>
            )}
          </div>
          {/* District */}
          <div>
            <label htmlFor="district" className="block font-medium">
              District
            </label>
            <input
              type="text"
              id="district"
              name="district"
              value={formData.district}
              onChange={handleInputChange}
              placeholder="Enter your district"
              className="mt-1 w-full border border-[#D8CDAF] rounded px-3 py-2"
            />
          </div>
          {/* Mandal */}
          <div>
            <label htmlFor="mandal" className="block font-medium">
              Mandal
            </label>
            <input
              type="text"
              id="mandal"
              name="mandal"
              value={formData.mandal}
              onChange={handleInputChange}
              placeholder="Enter your mandal"
              className="mt-1 w-full border border-[#D8CDAF] rounded px-3 py-2"
            />
          </div>
          {/* Village */}
          <div>
            <label htmlFor="village" className="block font-medium">
              Village
            </label>
            <input
              type="text"
              id="village"
              name="village"
              value={formData.village}
              onChange={handleInputChange}
              placeholder="Enter your village"
              className="mt-1 w-full border border-[#D8CDAF] rounded px-3 py-2"
            />
          </div>
          {/* Address Text */}
          <div className="md:col-span-3">
            <label htmlFor="address" className="block font-medium">
              Address
            </label>
            <textarea
              id="address"
              name="address"
              value={formData.address}
              onChange={handleInputChange}
              placeholder="Enter your full address"
              className="mt-1 w-full border border-[#D8CDAF] rounded px-3 py-2"
              rows="3"
            ></textarea>
          </div>
        </div>
      </div>
      {/* 11. Religion & Caste */}
      <div className="mb-4 grid grid-cols-1 md:grid-cols-2 gap-4">
        {/* Religion */}
        <div>
          <label htmlFor="religion" className="block font-medium">
            Religion *
          </label>
          <select
            name="religion"
            id="religion"
            value={formData.religion}
            onChange={handleInputChange}
            className="mt-1 w-full border border-[#D8CDAF] rounded px-3 py-2"
          >
            <option value="">Select Religion</option>
            <option value="hindu">Hindu</option>
            <option value="christian">Christian</option>
            <option value="muslim">Muslim</option>
            <option value="casteConverted">Caste Converted</option>
          </select>
          {errors.religion && (
            <p className="text-red-500 pt-1 text-sm">{errors.religion}</p>
          )}
        </div>
        {/* Caste */}
        <div>
          <label htmlFor="caste" className="block font-medium">
            Caste *
          </label>
          <input
            type="text"
            id="caste"
            name="caste"
            value={formData.caste}
            onChange={handleInputChange}
            placeholder="Enter your caste"
            className="mt-1 w-full border border-[#D8CDAF] rounded px-3 py-2"
          />
          {errors.caste && (
            <p className="text-red-500 pt-1 text-sm">{errors.caste}</p>
          )}
        </div>
      </div>
      {/* 12. Sub-Caste */}
      <div className="mb-4">
        <label htmlFor="subCaste" className="block font-medium">
          Sub-Caste
        </label>
        <input
          type="text"
          id="subCaste"
          name="subCaste"
          value={formData.subCaste}
          onChange={handleInputChange}
          placeholder="Enter your sub-caste"
          className="mt-1 w-full border border-[#D8CDAF] rounded px-3 py-2"
        />
      </div>
      {/* 13. If Hindu: Star, Moon Sign, Padam, Gothram */}
      {isHindu && (
        <div className="mb-4">
          <h3 className="font-medium mb-2">Hindu Specific Details</h3>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            {/* Star */}
            <div>
              <label htmlFor="star" className="block font-medium">
                Star
              </label>
              <input
                type="text"
                id="star"
                name="star"
                value={formData.star}
                onChange={handleInputChange}
                placeholder="Enter your star"
                className="mt-1 w-full border border-[#D8CDAF] rounded px-3 py-2"
              />
            </div>
            {/* Moon Sign/Raasi */}
            <div>
              <label htmlFor="moonSign" className="block font-medium">
                Moon Sign/Raasi
              </label>
              <input
                type="text"
                id="moonSign"
                name="moonSign"
                value={formData.moonSign}
                onChange={handleInputChange}
                placeholder="Enter your moon sign"
                className="mt-1 w-full border border-[#D8CDAF] rounded px-3 py-2"
              />
            </div>
            {/* Padam */}
            <div>
              <label htmlFor="padam" className="block font-medium">
                Padam
              </label>
              <input
                type="text"
                id="padam"
                name="padam"
                value={formData.padam}
                onChange={handleInputChange}
                placeholder="Enter your padam"
                className="mt-1 w-full border border-[#D8CDAF] rounded px-3 py-2"
              />
            </div>
            {/* Gothram */}
            <div>
              <label htmlFor="gothram" className="block font-medium">
                Gothram
              </label>
              <input
                type="text"
                id="gothram"
                name="gothram"
                value={formData.gothram}
                onChange={handleInputChange}
                placeholder="Enter your gothram"
                className="mt-1 w-full border border-[#D8CDAF] rounded px-3 py-2"
              />
            </div>
            {/* Additional Gothram for Arya Vysyas */}
            {formData.caste.toLowerCase() === "arya vysyas" && (
              <>
                {/* Uncle’s Gothram */}
                <div>
                  <label htmlFor="uncleGothram" className="block font-medium">
                    Uncle’s Gothram
                  </label>
                  <input
                    type="text"
                    id="uncleGothram"
                    name="uncleGothram"
                    value={formData.uncleGothram}
                    onChange={handleInputChange}
                    placeholder="Enter uncle’s gothram"
                    className="mt-1 w-full border border-[#D8CDAF] rounded px-3 py-2"
                  />
                </div>
                {/* Swagothram */}
                <div>
                  <label htmlFor="swagothram" className="block font-medium">
                    Swagothram
                  </label>
                  <input
                    type="text"
                    id="swagothram"
                    name="swagothram"
                    value={formData.swagothram}
                    onChange={handleInputChange}
                    placeholder="Enter swagothram"
                    className="mt-1 w-full border border-[#D8CDAF] rounded px-3 py-2"
                  />
                </div>
              </>
            )}
            {/* Kuja Dosham */}
            <div>
              <label htmlFor="kujaDosham" className="block font-medium">
                Kuja Dosham
              </label>
              <select
                name="kujaDosham"
                id="kujaDosham"
                value={formData.kujaDosham}
                onChange={handleInputChange}
                className="mt-1 w-full border border-[#D8CDAF] rounded px-3 py-2"
              >
                <option value="">Select Option</option>
                <option value="yes">Yes</option>
                <option value="no">No</option>
                <option value="dontKnow">Don't Know</option>
              </select>
            </div>
          </div>
        </div>
      )}
      {/* 14. Caste Converted */}
      <div className="mb-4">
        <label htmlFor="isCasteConverted" className="block font-medium">
          Are you converted your Caste? *
        </label>
        <select
          name="isCasteConverted"
          id="isCasteConverted"
          value={formData.isCasteConverted}
          onChange={handleInputChange}
          className="mt-1 w-full border border-[#D8CDAF] rounded px-3 py-2"
        >
          <option value="">Select Option</option>
          <option value="yes">Yes</option>
          <option value="no">No</option>
        </select>
      </div>
      {/* 15. Height & 18. Blood Group */}
      <div className="mb-4 grid grid-cols-1 md:grid-cols-2 gap-4">
        {/* Height */}
        <div>
          <label htmlFor="height" className="block font-medium">
            Height *
          </label>
          <input
            type="text"
            id="height"
            name="height"
            value={formData.height}
            onChange={handleInputChange}
            placeholder="Enter your height"
            className="mt-1 w-full border border-[#D8CDAF] rounded px-3 py-2"
          />
          {errors.height && (
            <p className="text-red-500 pt-1 text-sm">{errors.height}</p>
          )}
        </div>
        {/* Blood Group */}
        <div>
          <label htmlFor="bloodGroup" className="block font-medium">
            Blood Group
          </label>
          <select
            name="bloodGroup"
            id="bloodGroup"
            value={formData.bloodGroup}
            onChange={handleInputChange}
            className="mt-1 w-full border border-[#D8CDAF] rounded px-3 py-2"
          >
            <option value="">Select Blood Group</option>
            <option value="A+">A+</option>
            <option value="A-">A-</option>
            <option value="B+">B+</option>
            <option value="B-">B-</option>
            <option value="AB+">AB+</option>
            <option value="AB-">AB-</option>
            <option value="O+">O+</option>
            <option value="O-">O-</option>
          </select>
        </div>
      </div>
      {/* 16. Health Condition */}
      <div className="mb-4">
        <label htmlFor="healthCondition" className="block font-medium">
          Health Condition
        </label>
        <textarea
          id="healthCondition"
          name="healthCondition"
          value={formData.healthCondition}
          onChange={handleInputChange}
          placeholder="Enter any health conditions"
          className="mt-1 w-full border border-[#D8CDAF] rounded px-3 py-2"
          rows="3"
        ></textarea>
      </div>
      {/* 19. Mother Tongue */}
      <div className="mb-4">
        <label htmlFor="motherTongue" className="block font-medium">
          Mother Tongue
        </label>
        <select
          name="motherTongue"
          id="motherTongue"
          value={formData.motherTongue}
          onChange={handleInputChange}
          className="mt-1 w-full border border-[#D8CDAF] rounded px-3 py-2"
        >
          <option value="">Select Mother Tongue</option>
          <option value="telugu">Telugu</option>
          <option value="kannada">Kannada</option>
          <option value="tamil">Tamil</option>
          <option value="odia">Odia</option>
          <option value="english">English</option>
          <option value="hindi">Hindi</option>
          <option value="urdu">Urdu</option>
        </select>
      </div>
      {/* 20. Food Preferences */}
      <div className="mb-4">
        <label htmlFor="food" className="block font-medium">
          Food Preferences
        </label>
        <select
          name="food"
          id="food"
          value={formData.food}
          onChange={handleInputChange}
          className="mt-1 w-full border border-[#D8CDAF] rounded px-3 py-2"
        >
          <option value="">Select Food Preference</option>
          <option value="vegetarian">Vegetarian</option>
          <option value="nonVegetarian">Non-Vegetarian</option>
          <option value="eggetarian">Eggetarian</option>
          <option value="notParticular">Not Particular</option>
        </select>
      </div>
      <div className="mb-4 grid grid-cols-1 md:grid-cols-2 gap-4">
        {/* Complexion */}
        <div>
          <label htmlFor="complexion" className="block font-medium">
            Complexion *
          </label>
          <input
            type="text"
            id="complexion"
            name="complexion"
            value={formData.complexion}
            onChange={handleInputChange}
            className="mt-1 w-full border border-[#D8CDAF] rounded px-3 py-2"
          />
        </div>
        {/* Marital Status */}
        <div>
          <label htmlFor="maritalStatus" className="block font-medium">
            Marital Status *
          </label>
          <select
            id="maritalStatus"
            name="maritalStatus"
            value={formData.maritalStatus}
            onChange={handleInputChange}
            className="mt-1 w-full border border-[#D8CDAF] rounded px-3 py-2"
          >
            <option value="">Select Marital Status</option>
            <option value="Unmarried">Unmarried</option>
            <option value="Divorced">Divorced</option>
            <option value="Widowed">Widowed</option>
          </select>
        </div>
      </div>
      {/* Divorced/Widowed conditional fields */}
      {selectedMaritalStatus === "Divorced" && (
        <>
          {/* Divorced-specific fields */}
          <div className="mb-4">
            <label
              htmlFor="divorceDetails.dateOfMarriage"
              className="block font-medium"
            >
              Date of Marriage
            </label>
            <input
              type="date"
              id="divorceDetails.dateOfMarriage"
              name="divorceDetails.dateOfMarriage"
              value={formData.divorceDetails.dateOfMarriage}
              onChange={handleInputChange}
              className="mt-1 w-full border border-[#D8CDAF] rounded px-3 py-2"
            />
          </div>
          <div className="mb-4">
            <label
              htmlFor="divorceDetails.dateOfDivorce"
              className="block font-medium"
            >
              Date of Divorce
            </label>
            <input
              type="date"
              id="divorceDetails.dateOfDivorce"
              name="divorceDetails.dateOfDivorce"
              value={formData.divorceDetails.dateOfDivorce}
              onChange={handleInputChange}
              className="mt-1 w-full border border-[#D8CDAF] rounded px-3 py-2"
            />
          </div>
          <div className="mb-4">
            <label
              htmlFor="divorceDetails.reasonForDivorce"
              className="block font-medium"
            >
              Reason for Divorce
            </label>
            <input
              type="text"
              id="divorceDetails.reasonForDivorce"
              name="divorceDetails.reasonForDivorce"
              value={formData.divorceDetails.reasonForDivorce}
              onChange={handleInputChange}
              className="mt-1 w-full border border-[#D8CDAF] rounded px-3 py-2"
              placeholder="Enter reason for divorce"
            />
          </div>
          <div className="mb-4">
            <label htmlFor="divorceCertificate" className="block font-medium">
              Upload Divorce Certificate
            </label>
            <input
              type="file"
              id="divorceCertificate"
              name="divorceCertificate"
              accept="image/*, application/pdf"
              onChange={handleInputChange}
              className="mt-1 w-full border border-[#D8CDAF] rounded px-3 py-2"
            />
          </div>
          {/* Children Details */}
          <div className="mb-4">
            <h3 className="font-medium mb-2">Children Details</h3>
            {/* Sons */}
            <div className="mb-4">
              <label className="block font-medium">Number of Sons</label>
              <input
                type="number"
                name="divorceDetails.numberOfSons"
                value={formData.divorceDetails.sons.length}
                onChange={(e) => {
                  const num = parseInt(e.target.value) || 0;
                  const current = formData.divorceDetails.sons.length;
                  if (num > current) {
                    setFormData((prevData) => ({
                      ...prevData,
                      divorceDetails: {
                        ...prevData.divorceDetails,
                        sons: [
                          ...prevData.divorceDetails.sons,
                          ...Array(num - current).fill({
                            name: "",
                            age: "",
                            maritalStatus: "",
                          }),
                        ],
                      },
                    }));
                  } else {
                    setFormData((prevData) => ({
                      ...prevData,
                      divorceDetails: {
                        ...prevData.divorceDetails,
                        sons: prevData.divorceDetails.sons.slice(0, num),
                      },
                    }));
                  }
                }}
                className="mt-1 w-full border border-[#D8CDAF] rounded px-3 py-2"
                min="0"
              />
              {formData.divorceDetails.sons.map((son, index) => (
                <div
                  key={index}
                  className="mt-2 p-2 border border-[#D8CDAF] rounded"
                >
                  <h4 className="font-medium">Son {index + 1}</h4>
                  <div className="mb-2">
                    <label className="block font-medium">Name</label>
                    <input
                      type="text"
                      name={`divorceDetails.sons[${index}].name`}
                      value={son.name}
                      onChange={(e) =>
                        handleChildChange(
                          "divorceDetails.sons",
                          index,
                          "name",
                          e.target.value
                        )
                      }
                      className="mt-1 w-full border border-[#D8CDAF] rounded px-3 py-2"
                      placeholder="Enter son's name"
                    />
                  </div>
                  <div className="mb-2">
                    <label className="block font-medium">Age</label>
                    <input
                      type="number"
                      name={`divorceDetails.sons[${index}].age`}
                      value={son.age}
                      onChange={(e) =>
                        handleChildChange(
                          "divorceDetails.sons",
                          index,
                          "age",
                          e.target.value
                        )
                      }
                      className="mt-1 w-full border border-[#D8CDAF] rounded px-3 py-2"
                      placeholder="Enter son's age"
                      min="0"
                    />
                  </div>
                  {parseInt(son.age) >= 18 && (
                    <div className="mb-2">
                      <label className="block font-medium">
                        Marital Status
                      </label>
                      <select
                        name={`divorceDetails.sons[${index}].maritalStatus`}
                        value={son.maritalStatus}
                        onChange={(e) =>
                          handleChildChange(
                            "divorceDetails.sons",
                            index,
                            "maritalStatus",
                            e.target.value
                          )
                        }
                        className="mt-1 w-full border border-[#D8CDAF] rounded px-3 py-2"
                      >
                        <option value="">Select Status</option>
                        <option value="Single">Single</option>
                        <option value="Married">Married</option>
                        <option value="Divorced">Divorced</option>
                        <option value="Widowed">Widowed</option>
                      </select>
                    </div>
                  )}
                </div>
              ))}
            </div>
            {/* Daughters */}
            <div className="mb-4">
              <label className="block font-medium">Number of Daughters</label>
              <input
                type="number"
                name="divorceDetails.numberOfDaughters"
                value={formData.divorceDetails.daughters.length}
                onChange={(e) => {
                  const num = parseInt(e.target.value) || 0;
                  const current = formData.divorceDetails.daughters.length;
                  if (num > current) {
                    setFormData((prevData) => ({
                      ...prevData,
                      divorceDetails: {
                        ...prevData.divorceDetails,
                        daughters: [
                          ...prevData.divorceDetails.daughters,
                          ...Array(num - current).fill({
                            name: "",
                            age: "",
                            maritalStatus: "",
                          }),
                        ],
                      },
                    }));
                  } else {
                    setFormData((prevData) => ({
                      ...prevData,
                      divorceDetails: {
                        ...prevData.divorceDetails,
                        daughters: prevData.divorceDetails.daughters.slice(
                          0,
                          num
                        ),
                      },
                    }));
                  }
                }}
                className="mt-1 w-full border border-[#D8CDAF] rounded px-3 py-2"
                min="0"
              />
              {formData.divorceDetails.daughters.map((daughter, index) => (
                <div
                  key={index}
                  className="mt-2 p-2 border border-[#D8CDAF] rounded"
                >
                  <h4 className="font-medium">Daughter {index + 1}</h4>
                  <div className="mb-2">
                    <label className="block font-medium">Name</label>
                    <input
                      type="text"
                      name={`divorceDetails.daughters[${index}].name`}
                      value={daughter.name}
                      onChange={(e) =>
                        handleChildChange(
                          "divorceDetails.daughters",
                          index,
                          "name",
                          e.target.value
                        )
                      }
                      className="mt-1 w-full border border-[#D8CDAF] rounded px-3 py-2"
                      placeholder="Enter daughter's name"
                    />
                  </div>
                  <div className="mb-2">
                    <label className="block font-medium">Age</label>
                    <input
                      type="number"
                      name={`divorceDetails.daughters[${index}].age`}
                      value={daughter.age}
                      onChange={(e) =>
                        handleChildChange(
                          "divorceDetails.daughters",
                          index,
                          "age",
                          e.target.value
                        )
                      }
                      className="mt-1 w-full border border-[#D8CDAF] rounded px-3 py-2"
                      placeholder="Enter daughter's age"
                      min="0"
                    />
                  </div>
                  {parseInt(daughter.age) >= 18 && (
                    <div className="mb-2">
                      <label className="block font-medium">
                        Marital Status
                      </label>
                      <select
                        name={`divorceDetails.daughters[${index}].maritalStatus`}
                        value={daughter.maritalStatus}
                        onChange={(e) =>
                          handleChildChange(
                            "divorceDetails.daughters",
                            index,
                            "maritalStatus",
                            e.target.value
                          )
                        }
                        className="mt-1 w-full border border-[#D8CDAF] rounded px-3 py-2"
                      >
                        <option value="">Select Status</option>
                        <option value="Single">Single</option>
                        <option value="Married">Married</option>
                        <option value="Divorced">Divorced</option>
                        <option value="Widowed">Widowed</option>
                      </select>
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>
        </>
      )}
      {selectedMaritalStatus === "Widowed" && (
        <>
          {/* Widowed-specific fields */}
          <div className="mb-4">
            <label
              htmlFor="widowedDetails.dateOfMarriage"
              className="block font-medium"
            >
              Date of Marriage
            </label>
            <input
              type="date"
              id="widowedDetails.dateOfMarriage"
              name="widowedDetails.dateOfMarriage"
              value={formData.widowedDetails.dateOfMarriage}
              onChange={handleInputChange}
              className="mt-1 w-full border border-[#D8CDAF] rounded px-3 py-2"
            />
          </div>
          <div className="mb-4">
            <label
              htmlFor="widowedDetails.dateOfDeath"
              className="block font-medium"
            >
              Date of Death
            </label>
            <input
              type="date"
              id="widowedDetails.dateOfDeath"
              name="widowedDetails.dateOfDeath"
              value={formData.widowedDetails.dateOfDeath}
              onChange={handleInputChange}
              className="mt-1 w-full border border-[#D8CDAF] rounded px-3 py-2"
            />
          </div>
          <div className="mb-4">
            <label htmlFor="deathCertificate" className="block font-medium">
              Upload Death Certificate
            </label>
            <input
              type="file"
              id="deathCertificate"
              name="deathCertificate"
              accept="image/*, application/pdf"
              onChange={handleInputChange}
              className="mt-1 w-full border border-[#D8CDAF] rounded px-3 py-2"
            />
          </div>
          {/* Children Details */}
          <div className="mb-4">
            <h3 className="font-medium mb-2">Children Details</h3>
            {/* Sons */}
            <div className="mb-4">
              <label className="block font-medium">Number of Sons</label>
              <input
                type="number"
                name="widowedDetails.numberOfSons"
                value={formData.widowedDetails.sons.length}
                onChange={(e) => {
                  const num = parseInt(e.target.value) || 0;
                  const current = formData.widowedDetails.sons.length;
                  if (num > current) {
                    setFormData((prevData) => ({
                      ...prevData,
                      widowedDetails: {
                        ...prevData.widowedDetails,
                        sons: [
                          ...prevData.widowedDetails.sons,
                          ...Array(num - current).fill({
                            name: "",
                            age: "",
                            maritalStatus: "",
                          }),
                        ],
                      },
                    }));
                  } else {
                    setFormData((prevData) => ({
                      ...prevData,
                      widowedDetails: {
                        ...prevData.widowedDetails,
                        sons: prevData.widowedDetails.sons.slice(0, num),
                      },
                    }));
                  }
                }}
                className="mt-1 w-full border border-[#D8CDAF] rounded px-3 py-2"
                min="0"
              />
              {formData.widowedDetails.sons.map((son, index) => (
                <div
                  key={index}
                  className="mt-2 p-2 border border-[#D8CDAF] rounded"
                >
                  <h4 className="font-medium">Son {index + 1}</h4>
                  <div className="mb-2">
                    <label className="block font-medium">Name</label>
                    <input
                      type="text"
                      name={`widowedDetails.sons[${index}].name`}
                      value={son.name}
                      onChange={(e) =>
                        handleChildChange(
                          "widowedDetails.sons",
                          index,
                          "name",
                          e.target.value
                        )
                      }
                      className="mt-1 w-full border border-[#D8CDAF] rounded px-3 py-2"
                      placeholder="Enter son's name"
                    />
                  </div>
                  <div className="mb-2">
                    <label className="block font-medium">Age</label>
                    <input
                      type="number"
                      name={`widowedDetails.sons[${index}].age`}
                      value={son.age}
                      onChange={(e) =>
                        handleChildChange(
                          "widowedDetails.sons",
                          index,
                          "age",
                          e.target.value
                        )
                      }
                      className="mt-1 w-full border border-[#D8CDAF] rounded px-3 py-2"
                      placeholder="Enter son's age"
                      min="0"
                    />
                  </div>
                  {parseInt(son.age) >= 18 && (
                    <div className="mb-2">
                      <label className="block font-medium">
                        Marital Status
                      </label>
                      <select
                        name={`widowedDetails.sons[${index}].maritalStatus`}
                        value={son.maritalStatus}
                        onChange={(e) =>
                          handleChildChange(
                            "widowedDetails.sons",
                            index,
                            "maritalStatus",
                            e.target.value
                          )
                        }
                        className="mt-1 w-full border border-[#D8CDAF] rounded px-3 py-2"
                      >
                        <option value="">Select Status</option>
                        <option value="Single">Single</option>
                        <option value="Married">Married</option>
                        <option value="Divorced">Divorced</option>
                        <option value="Widowed">Widowed</option>
                      </select>
                    </div>
                  )}
                </div>
              ))}
            </div>
            {/* Daughters */}
            <div className="mb-4">
              <label className="block font-medium">Number of Daughters</label>
              <input
                type="number"
                name="widowedDetails.numberOfDaughters"
                value={formData.widowedDetails.daughters.length}
                onChange={(e) => {
                  const num = parseInt(e.target.value) || 0;
                  const current = formData.widowedDetails.daughters.length;
                  if (num > current) {
                    setFormData((prevData) => ({
                      ...prevData,
                      widowedDetails: {
                        ...prevData.widowedDetails,
                        daughters: [
                          ...prevData.widowedDetails.daughters,
                          ...Array(num - current).fill({
                            name: "",
                            age: "",
                            maritalStatus: "",
                          }),
                        ],
                      },
                    }));
                  } else {
                    setFormData((prevData) => ({
                      ...prevData,
                      widowedDetails: {
                        ...prevData.widowedDetails,
                        daughters: prevData.widowedDetails.daughters.slice(
                          0,
                          num
                        ),
                      },
                    }));
                  }
                }}
                className="mt-1 w-full border border-[#D8CDAF] rounded px-3 py-2"
                min="0"
              />
              {formData.widowedDetails.daughters.map((daughter, index) => (
                <div
                  key={index}
                  className="mt-2 p-2 border border-[#D8CDAF] rounded"
                >
                  <h4 className="font-medium">Daughter {index + 1}</h4>
                  <div className="mb-2">
                    <label className="block font-medium">Name</label>
                    <input
                      type="text"
                      name={`widowedDetails.daughters[${index}].name`}
                      value={daughter.name}
                      onChange={(e) =>
                        handleChildChange(
                          "widowedDetails.daughters",
                          index,
                          "name",
                          e.target.value
                        )
                      }
                      className="mt-1 w-full border border-[#D8CDAF] rounded px-3 py-2"
                      placeholder="Enter daughter's name"
                    />
                  </div>
                  <div className="mb-2">
                    <label className="block font-medium">Age</label>
                    <input
                      type="number"
                      name={`widowedDetails.daughters[${index}].age`}
                      value={daughter.age}
                      onChange={(e) =>
                        handleChildChange(
                          "widowedDetails.daughters",
                          index,
                          "age",
                          e.target.value
                        )
                      }
                      className="mt-1 w-full border border-[#D8CDAF] rounded px-3 py-2"
                      placeholder="Enter daughter's age"
                      min="0"
                    />
                  </div>
                  {parseInt(daughter.age) >= 18 && (
                    <div className="mb-2">
                      <label className="block font-medium">
                        Marital Status
                      </label>
                      <select
                        name={`widowedDetails.daughters[${index}].maritalStatus`}
                        value={daughter.maritalStatus}
                        onChange={(e) =>
                          handleChildChange(
                            "widowedDetails.daughters",
                            index,
                            "maritalStatus",
                            e.target.value
                          )
                        }
                        className="mt-1 w-full border border-[#D8CDAF] rounded px-3 py-2"
                      >
                        <option value="">Select Status</option>
                        <option value="Single">Single</option>
                        <option value="Married">Married</option>
                        <option value="Divorced">Divorced</option>
                        <option value="Widowed">Widowed</option>
                      </select>
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>
        </>
      )}
      {/* Smoke and Drink */}
      <div className="mb-4 grid grid-cols-1 md:grid-cols-2 gap-4">
        <div>
          <label htmlFor="smoke" className="block font-medium">
            Do you Smoke? *
          </label>
          <select
            id="smoke"
            name="smoke"
            value={formData.smoke}
            onChange={handleInputChange}
            className="mt-1 w-full border border-[#D8CDAF] rounded px-3 py-2"
          >
            <option value="">Select</option>
            <option value="Yes">Yes</option>
            <option value="No">No</option>
          </select>
        </div>
        <div>
          <label htmlFor="drink" className="block font-medium">
            Do you Drink? *
          </label>
          <select
            id="drink"
            name="drink"
            value={formData.drink}
            onChange={handleInputChange}
            className="mt-1 w-full border border-[#D8CDAF] rounded px-3 py-2"
          >
            <option value="">Select</option>
            <option value="Yes">Yes</option>
            <option value="No">No</option>
          </select>
        </div>
      </div>
      {/* Food Preferences */}
      <div className="mb-4">
        <label htmlFor="food" className="block font-medium">
          Food Preference *
        </label>
        <select
          id="food"
          name="food"
          value={formData.food}
          onChange={handleInputChange}
          className="mt-1 w-full border border-[#D8CDAF] rounded px-3 py-2"
        >
          <option value="">Select</option>
          <option value="Vegetarian">Vegetarian</option>
          <option value="Non-Vegetarian">Non-Vegetarian</option>
          <option value="Eggetarian">Eggetarian</option>
          <option value="Not-Particular">Not Particular</option>
        </select>
      </div>
      {/* About Me */}
      <div className="mb-4">
        <label htmlFor="aboutMe" className="block font-medium">
          About Me
        </label>
        <textarea
          id="aboutMe"
          name="aboutMe"
          value={formData.aboutMe}
          onChange={handleInputChange}
          className="mt-1 w-full border border-[#D8CDAF] rounded px-3 py-2"
          rows="4"
        ></textarea>
      </div>
      {/* 27. Hobbies and 28. Spoken Languages */}
      <div className="mb-4 flex space-x-4">
        <div className="flex-1">
          <label htmlFor="hobbies" className="block font-medium">
            Hobbies
          </label>
          <input
            type="text"
            id="hobbies"
            name="hobbies"
            value={formData.hobbies}
            onChange={handleInputChange}
            className="mt-1 w-full border border-gray-300 rounded px-3 py-2"
            placeholder="Enter your hobbies"
          />
        </div>
        <div className="flex-1">
          <label htmlFor="spokenLanguages" className="block font-medium">
            Spoken Languages
          </label>
          <input
            type="text"
            id="spokenLanguages"
            name="spokenLanguages"
            value={formData.spokenLanguages}
            onChange={handleInputChange}
            className="mt-1 w-full border border-gray-300 rounded px-3 py-2"
            placeholder="Enter languages you speak"
          />
        </div>
      </div>
      {/* 29. Time to Call */}
      <div className="mb-4">
        <label htmlFor="timeToCall" className="block font-medium">
          Time to Call
        </label>
        <input
          type="text"
          id="timeToCall"
          name="timeToCall"
          value={formData.timeToCall}
          onChange={handleInputChange}
          className="mt-1 w-full border border-gray-300 rounded px-3 py-2"
          placeholder="Preferred time to call"
        />
      </div>
      {/* 38. Application for */}
      <div className="mb-4">
        <label htmlFor="applicationFor" className="block font-medium">
          Application for *
        </label>
        <select
          id="applicationFor"
          name="applicationFor"
          value={formData.applicationFor}
          onChange={handleInputChange}
          className="mt-1 w-full border border-gray-300 rounded px-3 py-2"
        >
          <option value="">Select</option>
          <option value="Myself">Myself</option>
          <option value="Friend">Friend</option>
          <option value="Relative">Relative</option>
          <option value="Son">Son</option>
          <option value="Daughter">Daughter</option>
        </select>
      </div>
      {/* If application is not given by self */}
      {formData.applicationFor !== "Myself" && (
        <>
          {/* 1. Name of application filling person and 2. Mobile number to contact */}
          <div className="mb-4 flex space-x-4">
            <div className="flex-1">
              <label htmlFor="applicantName" className="block font-medium">
                Name of Applicant Filling Person
              </label>
              <input
                type="text"
                id="applicantName"
                name="applicantName"
                value={formData.applicantName}
                onChange={handleInputChange}
                className="mt-1 w-full border border-gray-300 rounded px-3 py-2"
                placeholder="Enter name"
              />
            </div>
            <div className="flex-1">
              <label htmlFor="applicantMobile" className="block font-medium">
                Mobile Number to Contact
              </label>
              <input
                type="text"
                id="applicantMobile"
                name="applicantMobile"
                value={formData.applicantMobile}
                onChange={handleInputChange}
                className="mt-1 w-full border border-gray-300 rounded px-3 py-2"
                placeholder="Enter mobile number"
              />
            </div>
          </div>
          {/* 3. Relation with applicant */}
          <div className="mb-4">
            <label
              htmlFor="relationWithApplicant"
              className="block font-medium"
            >
              Relation with Applicant
            </label>
            <input
              type="text"
              id="relationWithApplicant"
              name="relationWithApplicant"
              value={formData.relationWithApplicant}
              onChange={handleInputChange}
              className="mt-1 w-full border border-gray-300 rounded px-3 py-2"
              placeholder="Enter relation"
            />
          </div>
        </>
      )}
      {/* 39. Source and 40. Nearest Branch */}
      <div className="mb-4 flex space-x-4">
        <div className="flex-1">
          <label htmlFor="source" className="block font-medium">
            Source
          </label>
          <input
            type="text"
            id="source"
            name="source"
            value={formData.source}
            onChange={handleInputChange}
            className="mt-1 w-full border border-gray-300 rounded px-3 py-2"
            placeholder="Enter source of application"
          />
        </div>
        <div className="flex-1">
          <label htmlFor="nearestBranch" className="block font-medium">
            Nearest Branch
          </label>
          <input
            type="text"
            id="nearestBranch"
            name="nearestBranch"
            value={formData.nearestBranch}
            onChange={handleInputChange}
            className="mt-1 w-full border border-gray-300 rounded px-3 py-2"
            placeholder="Enter nearest branch"
          />
        </div>
      </div>
    </>
  );
};

export default Step1;
