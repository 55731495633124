import { useEffect, useState } from "react";

import landingbg from "../../assets/bgs/landingpage.png";

import img1 from "../../assets/carousel/img1.jpg";
import img2 from "../../assets/carousel/img2.jpg";
import img3 from "../../assets/carousel/img3.jpg";
import img4 from "../../assets/carousel/img4.jpg";
import img5 from "../../assets/carousel/img5.jpg";
import img6 from "../../assets/carousel/img6.jpg";

const About = () => {
  const [displayText, setDisplayText] = useState("");
  const text = `మీ ద్వారా ప్రొఫైల్‌ని సృష్టించడానికి కొంత సమయం ఇవ్వండి, మేము మీకు పరిపూర్ణ భాగస్వామిని చేరుకోవడానికి సహాయం చేస్తాము..`;
  const [currentIndex, setCurrentIndex] = useState(0);
  const [fade, setFade] = useState(false);

  const images = [img1, img2, img3, img4, img5, img6];

  // useEffect(() => {
  //   let index = 0;

  //   const typingInterval = setInterval(() => {
  //     if (index < text.length - 1) {
  //       setDisplayText((prev) => prev + text[index]);
  //       index++;
  //     } else {
  //       clearInterval(typingInterval);
  //     }
  //   }, 80);

  //   return () => clearInterval(typingInterval);
  // }, [text]);

  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     setFade(true);
  //     setTimeout(() => {
  //       setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
  //       setFade(false);
  //     }, 500);
  //   }, 2000);

  //   return () => clearInterval(interval);
  // }, [images.length]);

  return (
    <div
      className="py-20 px-3 min-h-[70vh] w-full flex items-center justify-center"
      style={{
        backgroundImage: `url(${landingbg})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <div className="max-w-[1500px] mx-auto">
        <div className="grid gap-2 grid-cols-2 px-2">
          <div className="col-span-2 w-full md:col-span-1 flex items-center justify-center">
            <div
              className={`transition-opacity z-[2] duration-500 ${
                fade ? "opacity-0" : "opacity-100"
              }`}
            >
              <img
                src={images[currentIndex]}
                alt={`carousel-${currentIndex}`}
                className="h-[250px] object-cover"
              />
            </div>
          </div>
          <div className="col-span-2 w-full md:col-span-1 max-md:mt-10 text-2xl max-md:text-base text-center text-[#E63946] max-w-[380px] font-semibold flex items-center justify-center mx-auto">
            {text}
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
