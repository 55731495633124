import React from "react";
import RegisterForm from "../RegisterForm";
import tcbg from "../../assets/bgs/tcbg.png";

const Register = () => {
  return (
    <div
      className="max-w-[1500px] bg-center max-md:bg-right mx-auto"
      style={{
        backgroundImage: `url(${tcbg})`,
        backgroundSize: "cover",
      }}
    >
      <RegisterForm />
    </div>
  );
};

export default Register;
