import work from "../../assets/bgs/csbg.png";

import img1 from "../../assets/createAccount.png";
import img2 from "../../assets/browseProfiles.png";
import img3 from "../../assets/connect.png";
import img4 from "../../assets/interact.png";

const Working = () => {
  const workingData = [
    {
      id: 1,
      text: "Create Account",
      desc: "We are here to help you create beautiful profiles.",
      img: img1,
    },
    {
      id: 2,
      text: "Browse Profiles",
      desc: "Search profiles that match your interests.",
      img: img2,
    },
    {
      id: 3,
      text: "Connect",
      desc: "We help you connect with 24/7 support.",
      img: img3,
    },
    {
      id: 4,
      text: "Interact",
      desc: "Interactions between you and your matches are private.",
      img: img4,
    },
  ];

  return (
    <div
      className="py-16 min-h-[70vh] flex items-center max-md:bg-[left] justify-center max-md:bg-cover bg-center text-white"
      style={{ backgroundImage: `url(${work})` }}
    >
      <div className="max-w-[1500px] mx-auto">
        <h2 className="text-3xl text-center font-bold mb-16 text-black">
          How Does It Work?
        </h2>
        <div className="container mx-auto grid px-3 grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-8 place-items-center place-content-center">
          {workingData.map((item) => (
            <div
              key={item.id}
              className="text-center cursor-pointer mt-2 max-w-[300px] bg-gray-900 hover:bg-gray-950 hover:bg-opacity-65 bg-opacity-50 p-6 rounded-lg flex items-center justify-center flex-col"
            >
              <div className="w-[50px] h-[50px] mb-2 rounded-full bg-[#E63946] flex items-center justify-center">
                <img src={item.img} alt={item.text} />
              </div>
              <h3 className="text-2xl text-white font-semibold mb-4">
                {item.text}
              </h3>
              <p>{item.desc}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Working;
