import { useState, useEffect } from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import heroBg from "../assets/herobg.png";
import { toast } from "react-toastify";
import Step2 from "../components/OnBoarding/Step2";
import Step3 from "../components/OnBoarding/Step3";
import Step1 from "../components/OnBoarding/Step1";

const OnBoarding = () => {
  const [formData, setFormData] = useState({
    photo: null,
    firstName: "",
    surname: "",
    gender: "",
    mobile: "",
    email: "",
    idProof: "",
    idProofNumber: "",
    dateOfBirth: "",
    timeOfBirth: "",
    birthPlace: "",
    country: "",
    state: "",
    district: "",
    mandal: "",
    village: "",
    address: "",
    religion: "",
    caste: "",
    subCaste: "",
    star: "",
    moonSign: "",
    padam: "",
    gothram: "",
    uncleGothram: "",
    swagothram: "",
    kujaDosham: "",
    isCasteConverted: "",
    height: "",
    bloodGroup: "",
    motherTongue: "",
    healthCondition: "",
    maritalStatus: "",
    divorceDetails: {
      dateOfMarriage: "",
      dateOfDivorce: "",
      reasonForDivorce: "",
      divorceCertificate: null,
      hasChildren: "",
      sons: [],
      daughters: [],
    },
    widowedDetails: {
      dateOfMarriage: "",
      dateOfDeath: "",
      deathCertificate: null,
      hasChildren: "",
      sons: [],
      daughters: [],
    },
    smoke: "",
    drink: "",
    food: "",
    complexion: "",
    aboutMe: "",
    hobbies: "",
    spokenLanguages: "",
    altMobile: "",
    altEmail: "",
    timeToCall: "",
    applicationFor: "Myself",
    applicantName: "",
    applicantMobile: "",
    relationWithApplicant: "",
    source: "",
    nearestBranch: "",
    education: "",
    competitiveExam: "",
    university: "",
    employedIn: "",
    currentEducation: "",
    universityStudying: "",
    addressOfUniversity: "",
    yearOfPursuing: "",
    profession: "",
    designation: "",
    workingLocation: "",
    workingState: "",
    city: "",
    locationAddress: "",
    companyName: "",
    workingSince: "",
    totalExperience: "",
    passportNumber: "",
    dcountry: "",
    dstate: "",
    visaType: "",
    validFrom: "",
    validTill: "",
    workingCompanyName: "",
    companyPresentAddress: "",
    propertyDetails: "",
    annualIncome: "",
    colleagueName: "",
    fatherName: "",
    fatherReligion: "",
    fatherCaste: "",
    isFatherConverted: false,
    fatherStatus: "",
    fatherHealthCondition: "",
    fatherMobileNumber: "",
    fatherEmploymentStatus: "",
    fatherProfession: "",
    fatherAbout: "",
    fatherAnnualIncome: "",
    fatherDesignation: "",
    fatherAddress: "",
    fatherPropertyDetails: "",
    fatherPension: "",
    motherName: "",
    motherMaidenName: "",
    motherReligion: "",
    motherCaste: "",
    isMotherConverted: false,
    motherStatus: "",
    motherHealthCondition: "",
    motherWorkingSector: "",
    motherMobileNumber: "",
    motherEmploymentStatus: "",
    motherProfession: "",
    motherAbout: "",
    motherAnnualIncome: "",
    motherDesignation: "",
    motherAddress: "",
    motherPropertyDetails: "",
    motherPension: "",
    permanentAddress: "",
    presentAddress: "",
    numberOfBrothers: 0,
    numberOfSisters: 0,
    brothers: [],
    sisters: [],
    referenceName: "",
    referenceMobileNumber: "",
    relation: "",
    referenceAddress: "",
  });

  const [errors, setErrors] = useState({});
  const [selectedMaritalStatus, setSelectedMaritalStatus] = useState("");
  const [isHindu, setIsHindu] = useState(false);
  const [age, setAge] = useState(null);
  const [step, setStep] = useState(0);

  // Function to handle child input changes
  const handleChildChange = (type, index, field, value) => {
    setFormData((prevData) => {
      const updatedChildren = prevData[type].map((child, i) =>
        i === index ? { ...child, [field]: value } : child
      );
      return {
        ...prevData,
        [type]: updatedChildren,
      };
    });
  };

  // Handle input changes
  const handleInputChange = (e) => {
    const { name, value, files } = e.target;

    if (
      name === "photo" ||
      name === "divorceCertificate" ||
      name === "deathCertificate"
    ) {
      setFormData((prevData) => ({
        ...prevData,
        [name]: files[0],
      }));
    } else if (
      name.startsWith("divorceDetails.") ||
      name.startsWith("widowedDetails.")
    ) {
      const [section, field] = name.split(".");
      setFormData((prevData) => ({
        ...prevData,
        [section]: {
          ...prevData[section],
          [field]: value,
        },
      }));
    } else if (name.startsWith("brotherName")) {
      const index = name.replace("brotherName", "");
      setFormData((prev) => {
        const updatedBrothers = [...prev.brothers];
        updatedBrothers[index] = {
          ...updatedBrothers[index],
          name: value,
        };
        return { ...prev, brothers: updatedBrothers };
      });
    } else if (name.startsWith("sisterName")) {
      const index = name.replace("sisterName", "");
      setFormData((prev) => {
        const updatedSisters = [...prev.sisters];
        updatedSisters[index] = {
          ...updatedSisters[index],
          name: value,
        };
        return { ...prev, sisters: updatedSisters };
      });
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));

      // Conditional logic for religion
      if (name === "religion") {
        setIsHindu(value === "Hindu");
      }

      // Conditional logic for marital status
      if (name === "maritalStatus") {
        setSelectedMaritalStatus(value);
      }
    }
  };

  // Calculate age from date of birth
  useEffect(() => {
    if (formData.dateOfBirth) {
      const today = new Date();
      const dob = new Date(formData.dateOfBirth);
      let calculatedAge = today.getFullYear() - dob.getFullYear();
      const m = today.getMonth() - dob.getMonth();
      if (m < 0 || (m === 0 && today.getDate() < dob.getDate())) {
        calculatedAge--;
      }
      setAge(calculatedAge);

      if (calculatedAge < 18 || calculatedAge > 70) {
        toast.error("Age must be between 18 and 70 years.");
      }
    }
  }, [formData.dateOfBirth]);

  // Validate form before submission
  const validateForm = () => {
    const newErrors = {};

    if (step === 0) {
      // Basic required fields
      //   if (!formData.photo) newErrors.photo = "Photo is required.";
      if (!formData.firstName) newErrors.firstName = "Full Name is required.";
      if (!formData.surname) newErrors.surname = "Surname is required.";
      if (!formData.gender) newErrors.gender = "Gender is required.";
      if (!formData.mobile) newErrors.mobile = "Mobile Number is required.";
      if (!formData.email) newErrors.email = "Email is required.";
      if (!formData.idProof) newErrors.idProof = "ID Proof is required.";
      if (!formData.idProofNumber)
        newErrors.idProofNumber = "ID Proof Number is required.";
      if (!formData.dateOfBirth)
        newErrors.dateOfBirth = "Date of Birth is required.";
      if (!formData.timeOfBirth)
        newErrors.timeOfBirth = "Time of Birth is required.";
      if (!formData.birthPlace)
        newErrors.birthPlace = "Birth Place is required.";
      if (!formData.country) newErrors.country = "Country is required.";
      if (!formData.state) newErrors.state = "State is required.";
      if (!formData.religion) newErrors.religion = "Religion is required.";
      if (!formData.caste) newErrors.caste = "Caste is required.";
      if (!formData.height) newErrors.height = "Height is required.";
      if (!formData.maritalStatus)
        newErrors.maritalStatus = "Marital Status is required.";
      if (!formData.complexion)
        newErrors.complexion = "complexion is required.";

      if (age < 18 || age > 70) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          dateOfBirth: "Age must be between 18 and 70 years.",
        }));
      }
    } else if (step === 1) {
      if (!formData.education) newErrors.education = "Education is required.";
      if (!formData.employedIn) newErrors.employedIn = "EmployedIn is required";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  // Handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();

    if (validateForm()) {
      if (step === 0) {
        // Save and Proceed
        setStep((p) => p + 1);
        window.scrollTo({ top: 0, behavior: "smooth" });
      }
    } else if (step === 1) {
      // Save and Proceed
      setStep((p) => p + 1);
      window.scrollTo({ top: 0, behavior: "smooth" });
    } else {
      console.log(errors);
      toast.error("Please fix the errors in the form.");
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
  };

  return (
    <>
      <Header />
      <div
        className="w-full py-[120px] min-h-[95vh] flex items-center justify-center bg-gray-400"
        style={{
          backgroundImage: `url(${heroBg})`,
          backgroundSize: "cover",
          backgroundBlendMode: "multiply",
          backgroundPosition: "left",
          backgroundAttachment: "fixed",
        }}
      >
        <div className="w-[95%] max-w-[1078px] bg-[#FFFFF0] text-[#5B3A29] opacity-95 rounded shadow-md p-6">
          <h2 className="pt-3 pb-5 font-semibold text-2xl max-md:text-xl text-center">
            Customer Application
          </h2>
          <form onSubmit={handleSubmit}>
            {step === 0 && (
              <>
                <Step1
                  formData={formData}
                  errors={errors}
                  handleChildChange={handleChildChange}
                  handleInputChange={handleInputChange}
                  isHindu={isHindu}
                  setFormData={setFormData}
                  selectedMaritalStatus={selectedMaritalStatus}
                />
              </>
            )}

            {step === 1 && (
              <>
                <Step2
                  formData={formData}
                  handleInputChange={handleInputChange}
                />
              </>
            )}

            {step === 2 && (
              <>
                <Step3
                  formData={formData}
                  handleInputChange={handleInputChange}
                />
              </>
            )}

            {/* Save & Next */}
            <button
              type="submit"
              className="w-full text-white font-medium py-2 px-4 rounded bg-[#a50029] hover:bg-[#800020]"
            >
              Save & Next
            </button>
          </form>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default OnBoarding;
