import React from "react";

const Step2 = ({ formData, handleInputChange }) => {
  return (
    <div>
      <h2 className="pt-3 pb-5 font-semibold text-2xl">
        Education & Employment
      </h2>

      {/* Competitive Exam Qualified for */}
      <div className="flex flex-wrap">
        <div className="w-full md:w-1/2  mb-4 pr-2 max-md:pr-0">
          <label htmlFor="competitiveExam" className="block font-medium">
            Competitive Exam Qualified for
          </label>
          <select
            id="competitiveExam"
            name="competitiveExam"
            value={formData.competitiveExam}
            onChange={handleInputChange}
            className="mt-1 w-full border border-gray-300 rounded px-3 py-2"
          >
            <option value="">Select</option>
            <option value="JEE Mains">JEE Mains</option>
            <option value="JEE Advanced">JEE Advanced</option>
            <option value="UPSE">UPSE</option>
            <option value="NEET">NEET</option>
          </select>
        </div>

        {/* University */}
        <div className="w-full md:w-1/2 pl-2  mb-4 max-md:pl-0">
          <label htmlFor="university" className="block font-medium">
            University
          </label>
          <input
            type="text"
            id="university"
            name="university"
            value={formData.university}
            onChange={handleInputChange}
            className="mt-1 w-full border border-gray-300 rounded px-3 py-2"
            placeholder="Enter your university"
          />
        </div>
      </div>

      {/* Employed in */}
      <div className="flex flex-wrap mb-4">
        <div className="w-full md:w-1/2 pr-2 max-md:pr-0">
          <label htmlFor="employedIn" className="block font-medium">
            Employed in *
          </label>
          <select
            id="employedIn"
            name="employedIn"
            value={formData.employedIn}
            onChange={handleInputChange}
            className="mt-1 w-full border border-gray-300 rounded px-3 py-2"
          >
            <option value="">Select</option>
            <option value="Private">Private</option>
            <option value="Government">Government</option>
            <option value="Unemployed">Unemployed</option>
            <option value="Student">Student</option>
            <option value="Business">Business</option>
          </select>
        </div>
      </div>

      {/* Conditional Fields based on Employment Status */}
      {formData.employedIn === "Student" && (
        <div>
          <h3 className="font-medium">If Student</h3>
          <div className="flex flex-wrap mb-4">
            <div className="w-full md:w-1/4 pr-2 mb-4 max-md:pr-0">
              <label htmlFor="currentEducation" className="block font-medium">
                Current Education (Pursuing)
              </label>
              <input
                type="text"
                id="currentEducation"
                name="currentEducation"
                value={formData.currentEducation}
                onChange={handleInputChange}
                className="mt-1 w-full border border-gray-300 rounded px-3 py-2"
                placeholder="Current education"
              />
            </div>
            <div className="w-full md:w-1/4  mb-4 pr-2 max-md:pr-0">
              <label htmlFor="universityStudying" className="block font-medium">
                University Studying
              </label>
              <input
                type="text"
                id="universityStudying"
                name="universityStudying"
                value={formData.universityStudying}
                onChange={handleInputChange}
                className="mt-1 w-full border border-gray-300 rounded px-3 py-2"
                placeholder="University you are studying in"
              />
            </div>
            <div className="w-full md:w-1/4  mb-4 pr-2 max-md:pr-0">
              <label
                htmlFor="addressOfUniversity"
                className="block font-medium"
              >
                Address of University
              </label>
              <input
                type="text"
                id="addressOfUniversity"
                name="addressOfUniversity"
                value={formData.addressOfUniversity}
                onChange={handleInputChange}
                className="mt-1 w-full border border-gray-300 rounded px-3 py-2"
                placeholder="Address of university"
              />
            </div>
            <div className="w-full md:w-1/4  mb-4 pl-2 max-md:pl-0">
              <label htmlFor="yearOfPursuing" className="block font-medium">
                Year of Pursuing
              </label>
              <input
                type="text"
                id="yearOfPursuing"
                name="yearOfPursuing"
                value={formData.yearOfPursuing}
                onChange={handleInputChange}
                className="mt-1 w-full border border-gray-300 rounded px-3 py-2"
                placeholder="Year of pursuing"
              />
            </div>
          </div>
        </div>
      )}

      {formData.employedIn && formData.employedIn !== "Student" && (
        <div>
          <div className="flex flex-wrap mb-4">
            <div className="w-full md:w-1/4 mb-4 pr-2 max-md:pr-0">
              <label htmlFor="workingSince" className="block font-medium">
                Working Since
              </label>
              <input
                type="date"
                id="workingSince"
                name="workingSince"
                value={formData.workingSince}
                onChange={handleInputChange}
                className="mt-1 w-full border border-gray-300 rounded px-3 py-2"
                placeholder="YYYY-MM-DD"
              />
            </div>
            <div className="w-full md:w-1/4 mb-4 pr-2 max-md:pr-0">
              <label htmlFor="totalExperience" className="block font-medium">
                Total Experience (Years)
              </label>
              <input
                type="number"
                id="totalExperience"
                name="totalExperience"
                value={formData.totalExperience}
                onChange={handleInputChange}
                className="mt-1 w-full border border-gray-300 rounded px-3 py-2"
                placeholder="Total Experience"
              />
            </div>
            <div className="w-full md:w-1/4 mb-4 pr-2 max-md:pr-0">
              <label htmlFor="passportNumber" className="block font-medium">
                Passport Number
              </label>
              <input
                type="text"
                id="passportNumber"
                name="passportNumber"
                value={formData.passportNumber}
                onChange={handleInputChange}
                className="mt-1 w-full border border-gray-300 rounded px-3 py-2"
                placeholder="Passport Number"
              />
            </div>
            <div className="w-full md:w-1/4 mb-4 pl-2 max-md:pl-0">
              <label htmlFor="workingLocation" className="block font-medium">
                Working Location
              </label>
              <select
                id="workingLocation"
                name="workingLocation"
                value={formData.workingLocation}
                onChange={handleInputChange}
                className="mt-1 w-full border border-gray-300 rounded px-3 py-2"
              >
                <option value="">Select</option>
                <option value="Abroad">Abroad</option>
                <option value="India">India</option>
              </select>
            </div>
          </div>

          {/* Conditional Fields based on Working Location */}
          {formData.workingLocation === "Abroad" && (
            <div>
              <div className="flex flex-wrap mb-4">
                <div className="w-full md:w-1/4 pr-2 mb-4 max-md:pr-0">
                  <label htmlFor="dcountry" className="block font-medium">
                    Country
                  </label>
                  <input
                    type="text"
                    id="dcountry"
                    name="dcountry"
                    value={formData.dcountry}
                    onChange={handleInputChange}
                    className="mt-1 w-full border border-gray-300 rounded px-3 py-2"
                    placeholder="Country"
                  />
                </div>
                <div className="w-full md:w-1/4 pr-2 mb-4 max-md:pr-0">
                  <label htmlFor="dstate" className="block font-medium">
                    State
                  </label>
                  <input
                    type="text"
                    id="dstate"
                    name="dstate"
                    value={formData.dstate}
                    onChange={handleInputChange}
                    className="mt-1 w-full border border-gray-300 rounded px-3 py-2"
                    placeholder="State"
                  />
                </div>
                <div className="w-full md:w-1/4 pr-2 mb-4 max-md:pr-0">
                  <label htmlFor="visaType" className="block font-medium">
                    Visa Type
                  </label>
                  <input
                    type="text"
                    id="visaType"
                    name="visaType"
                    value={formData.visaType}
                    onChange={handleInputChange}
                    className="mt-1 w-full border border-gray-300 rounded px-3 py-2"
                    placeholder="Visa Type"
                  />
                </div>
                <div className="w-full md:w-1/4 pl-2 mb-4 max-md:pl-0">
                  <label htmlFor="validFrom" className="block font-medium">
                    Valid From
                  </label>
                  <input
                    type="date"
                    id="validFrom"
                    name="validFrom"
                    value={formData.validFrom}
                    onChange={handleInputChange}
                    className="mt-1 w-full border border-gray-300 rounded px-3 py-2"
                  />
                </div>
              </div>
              <div className="flex flex-wrap mb-4">
                <div className="w-full md:w-1/4 pr-2 mb-4 max-md:pr-0">
                  <label htmlFor="validTill" className="block font-medium">
                    Valid Till
                  </label>
                  <input
                    type="date"
                    id="validTill"
                    name="validTill"
                    value={formData.validTill}
                    onChange={handleInputChange}
                    className="mt-1 w-full border border-gray-300 rounded px-3 py-2"
                  />
                </div>
                <div className="w-full md:w-1/4 pr-2 mb-4 max-md:pr-0">
                  <label
                    htmlFor="workingCompanyName"
                    className="block font-medium"
                  >
                    Working Company Name
                  </label>
                  <input
                    type="text"
                    id="workingCompanyName"
                    name="workingCompanyName"
                    value={formData.workingCompanyName}
                    onChange={handleInputChange}
                    className="mt-1 w-full border border-gray-300 rounded px-3 py-2"
                    placeholder="Working Company Name"
                  />
                </div>
                <div className="w-full md:w-1/4 pr-2 mb-4 max-md:pr-0">
                  <label
                    htmlFor="companyPresentAddress"
                    className="block font-medium"
                  >
                    Company's Present Address
                  </label>
                  <input
                    type="text"
                    id="companyPresentAddress"
                    name="companyPresentAddress"
                    value={formData.companyPresentAddress}
                    onChange={handleInputChange}
                    className="mt-1 w-full border border-gray-300 rounded px-3 py-2"
                    placeholder="Company's Present Address"
                  />
                </div>
                <div className="w-full md:w-1/4 pl-2 mb-4 max-md:pl-0">
                  <label
                    htmlFor="propertyDetails"
                    className="block font-medium"
                  >
                    Property Details
                  </label>
                  <input
                    type="text"
                    id="propertyDetails"
                    name="propertyDetails"
                    value={formData.propertyDetails}
                    onChange={handleInputChange}
                    className="mt-1 w-full border border-gray-300 rounded px-3 py-2"
                    placeholder="Property Details"
                  />
                </div>
              </div>
              <div className="flex flex-wrap mb-4">
                <div className="w-full md:w-1/4 pr-2 mb-4 max-md:pr-0">
                  <label htmlFor="annualIncome" className="block font-medium">
                    Annual Income
                  </label>
                  <input
                    type="text"
                    id="annualIncome"
                    name="annualIncome"
                    value={formData.annualIncome}
                    onChange={handleInputChange}
                    className="mt-1 w-full border border-gray-300 rounded px-3 py-2"
                    placeholder="Annual Income"
                  />
                </div>
                <div className="w-full md:w-1/4 pl-2 mb-4 max-md:pl-0">
                  <label htmlFor="colleagueName" className="block font-medium">
                    Colleague's Name
                  </label>
                  <input
                    type="text"
                    id="colleagueName"
                    name="colleagueName"
                    value={formData.colleagueName}
                    onChange={handleInputChange}
                    className="mt-1 w-full border border-gray-300 rounded px-3 py-2"
                    placeholder="Colleague's Name"
                  />
                </div>
                <div className="w-full md:w-1/4 pl-2 mb-4 max-md:pl-0">
                  <label
                    htmlFor="colleagueMobile"
                    className="block font-medium"
                  >
                    Colleague's Mobile No
                  </label>
                  <input
                    type="text"
                    id="colleagueMobile"
                    name="colleagueMobile"
                    value={formData.colleagueMobile}
                    onChange={handleInputChange}
                    className="mt-1 w-full border border-gray-300 rounded px-3 py-2"
                    placeholder="Colleague's Mobile No"
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default Step2;
